import React from 'react'

import DataContextProvider from '../../../explorer/map/DataContext'
import MapControlContextProvider from '../../../explorer/map/MapControlContext'
import Panel from '../../../../elem/panel/Panel'
import { mapPanel as panelClassConfig } from '../../../../../utils/styles/panelStyles'
import GetLatLongMenu from './GetLatLongMenu'

export default ({ width, submissionData }) => (
    <DataContextProvider>
        <MapControlContextProvider mapMenu={`SubmissionsMap`}>
            <Panel panelClassConfig={panelClassConfig(width)}>
                <GetLatLongMenu submissionData={submissionData} />
            </Panel>
        </MapControlContextProvider>
    </DataContextProvider>
)