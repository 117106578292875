import React, { useContext, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { FaChevronLeft } from 'react-icons/fa'
import urls from '../../../../utils/constants/urls'
import { useLocation } from 'react-router-dom'

import { DataContext } from '../DataContext'

export default () => {
    const { returnToList, submissionState } = useContext(DataContext)
    const location = useLocation()
    const history = useHistory()

    const onClick = useCallback(() => {
        returnToList()
            .then(() => {
                history.push(urls.manageSampleResults)
            })
            .catch(() => {
            })
    }, [returnToList])

    const clearinghouseNumber = useMemo(() => {
        if (submissionState && submissionState.facility && submissionState.facility.ClearinghouseNumber) {
            return submissionState.facility.ClearinghouseNumber
        }
        if (location.state && location.state.clearinghouse) {
            return location.state.clearinghouse
        }
        return ''
    }, [submissionState, location])

    return (
        <nav
            className="breadcrumb breadcrumbAlignment"
            aria-label="breadcrumbs"
        >
            <ul>
                <li>
                    <div className="link" onClick={onClick}>
                        <span className="icon is-small">
                            <FaChevronLeft />
                        </span>
                        <span>Return to Submissions List</span>
                    </div>
                    <div className='clearinghouseNumber'>
                        {submissionState && submissionState.upload
                            ? <span>
                                <b>Clearinghouse Number:</b> { clearinghouseNumber }
                            </span>
                            : <span></span>
                        }
                    </div>
                </li>
            </ul>
        </nav>
    )
}
