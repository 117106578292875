import React, { useContext } from 'react'

import { DataContext } from '../DataContext'
import Nav from './Nav'
import SubmitButton from './SubmitButton'
import LoadingWrapper from '../../../wrappers/LoadingWrapper'
import Content from './Content'
import Breadcrumbs from './Breadcrumbs'
import StatusBanner from '../StatusBanner'
import UnsavedChanges from '../UnsavedChanges'
import ContinueAnyway from '../ContinueAnyway'
import BeforePageChange from '../BeforePageChange'

export default () => {
    const { uploadConfig, isReview, viewOnly, submissionState } = useContext(DataContext)

    return (
        <div className="hero is-fullheight-with-navbar unset-justify-content">
            <Breadcrumbs />
            <div className="columns no-margin is-multiline">
                <StatusBanner uploadData={submissionState && submissionState.upload ? submissionState.upload : null} />
                <UnsavedChanges />
                <ContinueAnyway />
                <BeforePageChange />
                <div className="column is-12">
                    <div>
                        <div>
                            <div className="columns is-multiline">
                                <LoadingWrapper data={uploadConfig}>
                                    <div className="column is-10">
                                        <StatusBanner />
                                        <Content />
                                    </div>
                                    <div className="column is-2">
                                        <Nav />
                                        { !(isReview || viewOnly)  && <SubmitButton />}
                                    </div>
                                </LoadingWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
