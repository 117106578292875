import dayjs from 'dayjs'

const timeWindowOptions = [
    {
        name: 'All Time',
        amount: null,
        unit: 'year',
    },
    {
        name: '20 Years',
        amount: 20,
        unit: 'year',
    }
]

const filterTimeData = (data, timeDiffObject, timeDataField = 'SampleDate') => {
    if (timeDiffObject.amount) {
        const edgeDate = dayjs().subtract(
            timeDiffObject.amount,
            timeDiffObject.unit
        )
        const newData = data.filter(d => {
            const date = d[timeDataField]
            return dayjs(date).diff(edgeDate) >= 0
        })
        return newData
    } else {
        return data
    }
}

export { timeWindowOptions, filterTimeData }
export default filterTimeData
