import React, { useContext } from 'react'

import { timeWindowOptions } from '../../../utils/chart/timeWindow'
import { ParameterContext } from '../../wrappers/ParameterContext'
import { hasDateParams } from '../../../utils/chart/values'

export default ({
    timeWindow,
    setTimeWindow,
    displayTimeWindowDropdown,
    toggleTimeWindowDropdown,
    colorClass,
    explorer = 'sample',
    fieldName = 'SimpleSampleDate',
}) => {
    const { params } = useContext(ParameterContext)
    // hide this dropdown if there is a date window applied

    if (hasDateParams(params, explorer, fieldName)) {
        return null
    }

    if (!displayTimeWindowDropdown) {
        return null
    }
    
    return (
        <div className="timeWindowDropdownWrapper">
            {timeWindowOptions.map((option, idx) => {
                const active = timeWindow.name === option.name
                return (
                    <div 
                        key={`timeoption-${idx}`}
                        className="timeWindowOptionWrapper"
                        onClick={() => {
                            setTimeWindow(option)
                            toggleTimeWindowDropdown(false)
                        }}
                    >
                        <div className={`timeWindowOptionText ${active ? 'activeOption ' + colorClass : ''}`}>
                            {option.name}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}