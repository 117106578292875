import React from 'react'

export default ({ resetFunction }) => {
    return (
        <div
            className="panelButton zoomButton"
            onClick={() => resetFunction()}
        >
            <span className="panelTimeOptionText">Reset Zoom</span>
        </div>
    )
}