const getAxisYDomain = (from, to, filterFunction, offset, initialData, xAxisKey) => {
    const refData = initialData.filter(x => x[xAxisKey] < to + 1 && x[xAxisKey] > from - 1)
    const keys = Object.keys(refData[0])
    const values = keys.filter(filterFunction).map(x => refData[0][x])
    const initialValue = Math.min(...values.map(x => Array.isArray(x) ? Math.min(...x) : x).filter(x => !(x === null)))
    let [bottom, top] = [initialValue, initialValue]
    refData.forEach(d => {
        const minValue =  Math.min(...Object.keys(d).filter(filterFunction).map(x => {
            if (Array.isArray(d[x])) {
                return Math.min(...(d[x]))
            } else {
                return d[x]
            }
        }).filter(x => !(x === null))) 
        const maxValue = 
        Math.max(...Object.keys(d).filter(filterFunction).map(x => {
            if (Array.isArray(d[x])) {
                return Math.max(...(d[x]))
            } else {
                return d[x]
            }
        }).filter(x => !(x === null))) 
        
        if (maxValue > top) top = maxValue
        if (minValue < bottom) bottom = minValue
    })

    // let returnBottom = (bottom | 0) - offset
    let returnBottom = bottom  - offset
    if (returnBottom < 0) {
       returnBottom = 0 
    }
    // let returnTop = (top | 0 ) + offset
    let returnTop = top + offset
    if (returnTop <= 0) {
        returnTop = top
    }
    return [returnBottom, top]
}

export { getAxisYDomain }