import React, { useContext, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import {
    FaExclamationTriangle,
    FaTimes,
    FaQuestionCircle,
    FaExclamationCircle,
    FaArrowAltCircleUp,
    FaRegArrowAltCircleUp,
} from 'react-icons/fa'
import { IconContext } from 'react-icons'

import { AppStateContext } from '../explorer/AppStateContext'
import { UserContext } from '../../wrappers/UserContext'
import { IconTooltip as Tooltip } from '../../elem/Tooltip'
import { hasAccessToPWS } from '../../../utils/user/permissions'

const Icon = ({ children }) => (
    <IconContext.Provider value={{ size: '1.5em' }}>
        {children}
    </IconContext.Provider>
)

const Popup = ({ name, text, icon, closePopup }) => (
    <OutsideClickHandler onOutsideClick={() => closePopup()}>
        <div className="navbarPopupWrapper">
            <div className="navbarPopup">
                <div className="navbarPopupHeader">
                    <div className="navbarPopupHeaderIcon">{icon}</div>
                    <div className="navbarPopupHeaderText">{name}</div>
                    <div className="navbarPopupHeaderCloseIcon">
                        <FaTimes onClick={() => closePopup()} />
                    </div>
                </div>
                <div className="navbarPopupBody">
                    <div
                        className="navbarPopupText"
                        dangerouslySetInnerHTML={{
                            __html: text,
                        }}
                    />
                </div>
            </div>
        </div>
    </OutsideClickHandler>
)

const AlertButton = ({ text }) => {
    const [expanded, setExpanded] = useState(false)

    return (
        <>
            <button
                className="button infoButton"
                data-tip={`Alerts`}
                data-for={`alert-icon`}
                onClick={() => setExpanded(!expanded)}
            >
                <Icon>
                    <FaExclamationTriangle height={'1.5em'} width={'1.5em'} />
                </Icon>
                <Tooltip id={'alert-icon'} place={'bottom'} />
            </button>
            {expanded && (
                <Popup
                    text={text}
                    name={'Alerts'}
                    icon={
                        <FaExclamationTriangle
                            height={'1.5em'}
                            width={'1.5em'}
                        />
                    }
                    closePopup={() => setExpanded(false)}
                />
            )}
        </>
    )
}

const HelpButton = ({ text }) => {
    const [expanded, setExpanded] = useState(false)

    return (
        <>
            <button
                className="button infoButton"
                data-tip={`Help`}
                data-for={`help-icon`}
                onClick={() => setExpanded(!expanded)}
            >
                <Icon>
                    <FaQuestionCircle height={'1.5em'} width={'1.5em'} />
                </Icon>
                <Tooltip id={'help-icon'} place={'bottom'} />
            </button>
            {expanded && (
                <Popup
                    text={text}
                    name={'Help'}
                    icon={<FaQuestionCircle height={'1.5em'} width={'1.5em'} />}
                    closePopup={() => setExpanded(false)}
                />
            )}
        </>
    )
}

const DisclaimerButton = ({ text }) => {
    const [expanded, setExpanded] = useState(false)
    const { roles } = useContext(UserContext)

    // do not show this component if the user is not in the 'Agency' role
    if (!hasAccessToPWS(roles)) {
        return null
    }

    return (
        <>
            <button
                className={`button dangerButton`}
                data-tip={`Disclaimer`}
                data-for={`disclaimer-icon`}
                onClick={() => setExpanded(!expanded)}
            >
                <Icon>
                    <FaExclamationCircle />
                </Icon>
                <Tooltip id={'disclaimer-icon'} place={'bottom'} />
            </button>
            {expanded && (
                <Popup
                    text={text}
                    name={'Disclaimer'}
                    icon={
                        <FaExclamationCircle height={'1.5em'} width={'1.5em'} />
                    }
                    closePopup={() => setExpanded(false)}
                />
            )}
        </>
    )
}

const PromoteAllButton = () => {
    const { promoteAllExplorers, allExplorersPromoted } = useContext(AppStateContext)
    return (
        <>
            <button
                className="button infoButton"
                data-tip={allExplorersPromoted ? `Do Not Promote Selected Records` : `Promote Selected Records`}
                data-for={`promote-icon`}
                onClick={() => promoteAllExplorers(!allExplorersPromoted) } //if all explorers are promoted then unpromote them all, else promote them
            >
                <Icon> {
                    allExplorersPromoted 
                    ? <FaArrowAltCircleUp height={'1.5em'} width={'1.5em'}/>
                    : <FaRegArrowAltCircleUp height={'1.5em'} width={'1.5em'}/>
                }
                </Icon>
                <Tooltip id={'promote-icon'} place={'bottom'} />
            </button>
        </>
    )
}

export { AlertButton, HelpButton, DisclaimerButton, PromoteAllButton }
