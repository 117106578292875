import React, {
    useContext,
    useState,
    useEffect,
    useMemo,
    useCallback,
} from 'react'
import { useForm, FormContext } from 'react-hook-form'
import cloneDeep from 'lodash.clonedeep'
import merge from 'lodash.merge'
import dayjs from 'dayjs'

import { SimpleSpinner } from '../../../elem/Spinner'
import { ExistingEDDDataContext } from './ExistingEDDDataContext'
import Breadcrumbs from '../form/Breadcrumbs'
import SectionWrapper from '../form/SectionWrapper'
import { EDDDataContext } from './EDDDataContext'
import { DataContext } from '../DataContext'
import { parseUploadColumns } from '../../../../utils/table/parseColumns'
import EditableTable from '../../../elem/table/EditableTable'
import EDDValidation from './EDDValidation'
import {
    getValuesFromData,
} from '../../../../utils/submissions/values'
import deepEqual from 'deep-equal'
import BeforePageChange from '../BeforePageChange'
import { replaceBooleanWithInt } from '../../../../utils/submissions/numerical'
import ContinueAnyway from '../ContinueAnyway'
import ConfirmDeleteRecord from '../form/ConfirmDeleteRecord'
import deleteFunction from '../table/deleteFunction'

// const removeAndShiftRemaining = (data, rowIdx) => {
//     // keep the first elements of the array the same
//     const keep = data.slice(0, rowIdx)
//     // shift the elements after the deleted row into the other places
//     const remaining = data.slice(rowIdx)
//     remaining.shift()
//     return [...keep, ...remaining]
// }

// const deleteFunctionOld = (setData, formMethods, accessor, config, tableData) => {
//     const { cell, data } = tableData
//     const rowIdx = cell.row.index
//     // unregister the last row and set values in react-hook-form to new values
//     const formValues = formMethods.getValues({ nest: true })
//     const associatedValues = formValues[accessor]
//     const lastVisibleIndex = associatedValues.length - 1
//     associatedValues.map((entry, idx) => {
//         if (idx >= rowIdx && idx < lastVisibleIndex) {
//             const newValue = associatedValues[idx + 1]
//             const fieldToUpdate = `${accessor}[${idx}]`
//             Object.keys(entry).map(key => {
//                 const value = newValue[key]
//                 return formMethods.setValue(
//                     `${fieldToUpdate}.${key}`,
//                     value ? value : null
//                 )
//             })
//         }
//         return null
//     })

//     const revIdx = associatedValues.slice().reverse().findIndex(x => !!x)
//     const lastRowIdx = lastVisibleIndex - revIdx
//     const lastRow = formValues[accessor][lastRowIdx]
//     if (lastRowIdx === data.length - 1) {
//         // unregister the fields associated with the last row
//         Object.keys(lastRow).map(key => {
//             const fieldName = `${accessor}[${lastRowIdx}].${key}`
//             formMethods.setValue(fieldName, null)
//             formMethods.unregister(`${accessor}[${lastRowIdx}].${key}`)
//             return null
//         })
//     } else {
//         const newRow = data[lastRowIdx + 1]
//         Object.keys(lastRow).map(key => {
//             const fieldName = `${accessor}[${lastRowIdx}].${key}`
//             const newValue = getValueFromDataRow(key, newRow, config)
//             formMethods.setValue(fieldName, newValue)
//             return null
//         })
//     }

//     // if this is the last row, register a flag to denote this for data update
//     // console.log('last row idx:', lastRowIdx)
//     if (lastRowIdx === 0) {
//         formMethods.register(`${accessor}`)
//         formMethods.setValue(`${accessor}`, 'reset')
//     }

//     // update the data associated with the table
//     setData(existingData => {
//         const newData = removeAndShiftRemaining(existingData, rowIdx)
//         return newData
//     })
// }

const TableComponent = ({ eddConfig }) => {
    const { submissionState, setSubmissionState, beforePageChange, setErrorState, setDisplayDeleteModal } = useContext(
        DataContext
    )
    const { formMethods, setControlledPageSize } = useContext(EDDDataContext)
    const { loading, data, } = useContext(ExistingEDDDataContext)

    const [tableData, setTableData] = useState(data)

    useEffect(() => {
        setTableData(data)
    }, [data])

    useEffect(() => {
        if (tableData && tableData.length) {
            setSubmissionState({
                EDD: tableData,
            })
            setErrorState({})
        }
    }, [tableData])

    const columns = useMemo(
        () =>
            parseUploadColumns(
                tableData,
                eddConfig,
                submissionState,
                0,
                setDisplayDeleteModal
            ),
        [eddConfig, submissionState, formMethods, data, tableData]
    )

    if (loading) {
        return <SimpleSpinner />
    }

    return (
        <div className="formTableWrapper">
            <EditableTable
                data={tableData}
                columns={columns.returnColumns}
                setControlledPageSize={setControlledPageSize}
                beforePageChange={beforePageChange}
            />
        </div>
    )
}

const getInputNameFromConfig = (config, fieldName) => {
    if (!config) {
        return fieldName
    }
    const name = config.ColumnName
    switch (config.ControlType) {
        case 'TextBox':
            return name
        case 'Select':
            return `${name}Select`
        case 'MultiSelect':
            return `${name}Select`
        case 'DateDayPicker':
            return `${name}DateSelect`
        case 'DatePicker':
            return `${name}DateSelect`
        case 'Creatable':
            return `${name}Select`
        default:
            return name
    }
}

const isNotNullOrUndefined = value => {
    return !(typeof value === 'undefined' || value === null)
}

const getSelectValue = (values, initialValue, defaultValue) => {
    const value = isNotNullOrUndefined(initialValue)
        ? initialValue
        : defaultValue
        ? defaultValue
        : ''
    const option = values.find(
        x =>
            x.code === value.toString() ||
            x.code === value ||
            x.code === parseInt(value)
    )
    if (option) {
        return {
            label: option.codedescription,
            value: option.code,
            active: option.active,
        }
    } else {
        return value
    }
}

const getValueFromUploadConfig = (initialValue, config) => {
    const value = replaceBooleanWithInt(initialValue)
    switch (config.ControlType) {
        case 'TextBox':
            return value
        case 'Select':
            return getSelectValue(config.Values, value, config.DefaultValue)
        case 'MultiSelect':
            return getSelectValue(config.Values, value, config.DefaultValue)
        case 'DateDayPicker':
            return value
                ? dayjs(value)
                      .format('MM/DD/YYYY')
                      .toString()
                : null
        case 'DatePicker':
            return value
                ? dayjs(value)
                      .format('MM/DD/YYYY hh:mm A')
                      .toString()
                : null
        case 'Creatable':
            return getSelectValue(config.Values, value, config.DefaultValue)
        default:
            return value
    }
}

const transformDBValuesToFormValues = (x, uploadConfig) => {
    return Object.keys(x).reduce((acc, fieldName) => {
        let config = uploadConfig.find(x => x.ColumnName === fieldName)
        let name = getInputNameFromConfig(config, fieldName)

        const dbValue = x[fieldName]
        const value = config
            ? getValueFromUploadConfig(dbValue, config)
            : dbValue
        return {
            ...acc,
            [name]: value,
        }
    }, {})
}

const getDefaultValuesFromSubmissionState = (uploadConfig, data) => {
    if (data && data['EDD'] && data['EDD'].length && uploadConfig && uploadConfig.length) {
        const values = data['EDD'].map(x => {
            return transformDBValuesToFormValues(x, uploadConfig)
        })
        const defaultValues = {
            EDD: values,
        }
        return defaultValues
    } else {
        return null
    }
}

export default () => {
    const { eddConfig, validateTable, setFormMethods, loading } = useContext(
        EDDDataContext
    )
    const {
        submissionState,
        storedSubmissionState,
        setFormMethods: setDataContextFormMethods,
        setFormDirty,
        displayDeleteModal,
        setDisplayDeleteModal,
        removeAndShiftErrorState
    } = useContext(DataContext)
    const { rejectUpload, approveUpload, denied, setData } = useContext(
        ExistingEDDDataContext
    )
    const defaultValues = useMemo(
        () => getDefaultValuesFromSubmissionState(eddConfig, submissionState),
        [submissionState, eddConfig]
    )

    const formConfig = defaultValues
        ? { mode: 'onChange', defaultValues }
        : { mode: 'onChange' }
    const formMethods = useForm(formConfig)
    const { formState: { dirty } } = formMethods
    const [valid, setValid] = useState(false)
    const [comments, setComments] = useState(null)
    const [commentsError, setCommentsError] = useState(null)
    const [values, setValues] = useState(formMethods.watch({ nest: true }))
    const [validating, setValidating] = useState(false)

    useEffect(() => {
        const updatedValues = formMethods.watch({nest: true})
        if (!deepEqual(updatedValues, values)) {
            setValid(false)
            setValues(updatedValues)
        }
    }, [formMethods.watch({nest: true}), values])
    
    useEffect(() => {
        if (defaultValues) {
            formMethods.reset(defaultValues)
        }
    }, [defaultValues])

    useEffect(() => {
        if (!deepEqual(submissionState, storedSubmissionState) || dirty) {
            setFormDirty(true)
        } else {
            setFormDirty(false)
        }
    }, [submissionState, storedSubmissionState, dirty])

    useEffect(() => {
        setFormMethods(formMethods)
        setDataContextFormMethods(formMethods)
    }, [])

    const returnForm = useCallback(() => {
        if (comments) {
            rejectUpload(comments)
        } else {
            setCommentsError('Comments are required for denied EDDs.')
        }
    }, [comments])

    const onSubmit = useCallback(
        d => {
            const formData = getValuesFromData(d, eddConfig)
            const associatedData = formData['EDD']
            const td = cloneDeep(submissionState['EDD'])
            let eddData = []
            if (associatedData !== 'reset') {
                const test = td ? merge(td, associatedData) : associatedData
                eddData = test ? test : []
            }
            if (valid) {
                validateTable(eddData)
                    .then(validationResults => {
                        if (
                            Object.keys(validationResults.errors).length === 0
                        ) {
                            approveUpload(comments)
                        } else {
                            setValid(false)
                        }
                    })
                    .catch(e => {})
            } else {
                setValidating(true)
                validateTable(eddData)
                    .then(validationResults => {
                        if (
                            Object.keys(validationResults.errors).length === 0
                        ) {
                            setFormDirty(false)
                            setValid(true)
                        }
                    })
                    .catch(e => {})
                    .finally(() => setValidating(false))
            }
        },
        [submissionState, validateTable, eddConfig, approveUpload, valid]
    )

    return (
        <div className="hero is-fullheight-with-navbar unset-justify-content">
            <Breadcrumbs />
            <BeforePageChange />
            <ContinueAnyway />
            <div className="padding-left-sm padding-right-sm">
                <SectionWrapper title={'Review EDD'}>
                    <FormContext {...formMethods}>
                        <EDDValidation isValid={valid} isTable={true} />
                        <form
                            onSubmit={formMethods.handleSubmit(onSubmit)}
                            noValidate
                            className="columns is-multiline explorerForm is-centered"
                        >
                            <ConfirmDeleteRecord
                                displayDeleteModal={displayDeleteModal}
                                setDisplayDeleteModal={setDisplayDeleteModal}
                                accessor={'EDD'}
                                deleteFunction={
                                    deleteFunction.bind(
                                        this,
                                        setData,
                                        formMethods,
                                        'EDD',
                                        () => null,
                                        eddConfig,
                                        removeAndShiftErrorState
                                    )
                                }
                            />
                            <TableComponent eddConfig={eddConfig} />                            
                            <div className="column is-6">
                                <div className="content">
                                    <p>
                                    Once you are done reviewing changes, please either approve this submission or deny it.
                                    </p>
                                    <p>
                                        <b>Approve</b> will merge the changes into the Clearinghouse database
                                    </p>
                                    <p>
                                        <b>Deny</b> will not merge the changes into the Clearinghouse database
                                    </p>
                                    <p>
                                        <i>
                                            Note: Comments are required with a
                                            denied form.
                                        </i>
                                    </p>
                                </div>
                                <div className="control">
                                    <div className="label">Comments:</div>
                                    <div className="field">
                                        <textarea
                                            className={`textarea ${
                                                commentsError ? 'is-danger' : ''
                                            }`}
                                            onBlur={e => {
                                                setComments(e.target.value)
                                            }}
                                        />
                                    </div>
                                    {commentsError && (
                                        <div className="help is-danger">
                                            {commentsError}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="buttonWrapper">
                                <button
                                    type="submit"
                                    className={ `button is-medium is-link ${ loading ? 'is-loading' : '' }` }
                                    disabled={denied ? true : false}
                                >
                                    {valid ? `Approve` : `Validate / Save`}
                                </button>
                                <button
                                    type="button"
                                    className={`button is-medium ${denied ? 'is-loading' : ''}`}
                                    disabled={validating || loading ? true : false}
                                    onClick={() => returnForm()}
                                >
                                    Deny EDD
                                </button>
                            </div>
                        </form>
                    </FormContext>
                </SectionWrapper>
            </div>
        </div>
    )
}
