import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import { useForm } from 'react-hook-form'

import { APIRequestContext } from '../../../../wrappers/APIRequestContext'
import { DataContext } from '../../DataContext'
import withConfig from '../../../../wrappers/withConfig'
import { dnrInfoHelperText } from '../../../../../utils/submissions/helperText'

const DNRInformationTab = () => {
  const { activePanel } = useContext(DataContext)

  return (
      activePanel === 'DNR Information' && <DNRInformationTabComponent />
  )
}

const DNRInformationTabComponent = withConfig(({ config }) => {
	const { authenticatedFetch } = useContext(APIRequestContext)
	const {
		activePanel,
		activeAgency,
		submissionState,
		viewOnly,
		setFormMethods,
		setFormDirty,
		setSubmissionState,
	} = useContext(DataContext)
	
	const [DNRWellID, setDNRWellID] = useState(submissionState.facility.DNRWellID) // represents the display value in the UI field
	const [DNRInfo, setDNRInfo] = useState()// represents the value that will be saved to the submissionState to be submited 

	const [loading, setLoading] = useState(false)
	const formConfig = DNRWellID ? { mode: 'onChange', defaultValues: { 'DNRWellID': DNRWellID } } : {mode: 'onChange'}
	const formMethods = useForm(formConfig)
	const { handleSubmit, formState } = formMethods
	const { dirty } = formState
	const { API_URL } = config
	const [duplicateError, setDuplicateError] = useState(null)
	const [error, setError] = useState(null)

	const isReadOnly = useMemo(() => 
		!submissionState.facility.FacilityID || (submissionState.facility.FacilityID && submissionState.facility.DNRWellID)
	, [submissionState])

	const isDNRNotNull = useMemo(() => !!(submissionState && submissionState.facility && submissionState.facility.DNRWellID), [submissionState])

	useEffect(() => {
        if (DNRWellID) {
            if (isNaN(DNRWellID)) {
                setError("DNR Well ID must be a number.")
                return
            }
            if (parseFloat(DNRWellID) !== parseInt(DNRWellID)) {
                setError("DNR Well ID must be a whole number.")
                return
            }
            if (parseInt(DNRWellID) < 1) {
                setError("DNR Well ID cannot be less than 1.");
                return
            }
            if (DNRWellID.length > 9) {
                setError("There is no well that meets that search criteria.")
                return
            }
			else {
				setError(null)
			}
        }
	}, [DNRWellID])
	
	useEffect(() => {
		if (DNRInfo) {
			setSubmissionState(prevSubmissionState => {
				return {
					...prevSubmissionState,
					facility: {
						...prevSubmissionState.facility,
						...DNRInfo
					}
				}
			})
			setFormMethods(formMethods)
			formMethods.reset({ DNRWellID: DNRInfo.DNRWellID })	
		}
	}, [DNRInfo])

	useEffect(() => {
		setDNRWellID(prevDNRID => isDNRNotNull ? submissionState.facility.DNRWellID : prevDNRID)
	}, [submissionState])

	const checkDuplicateDNRID = useCallback(() => {
		setLoading(true)
		authenticatedFetch(
			`${API_URL}/upload/facilitySearch?alias=${null}&DNRWellID=${DNRWellID}&agencyCode=${activeAgency}`
		)
			.then(async response => {
				if (response.ok) {
					return response.json()
				} else {
					const error = await response.text()
					throw new Error(error)
				}
			})
			.then(response => {
				setDuplicateError(null)
				if (response.error) {
					setError(response.error)
					return
				}
				setDNRInfo({
					DNRRegistrationNumber_Full: response.facilityInfo.facility.DNRRegistrationNumber_Full,
					DNRWellID: parseInt(DNRWellID), 
					DNRWellStatus: response.facilityInfo.facility.DNRWellStatus,
					DNRWellType: response.facilityInfo.facility.DNRWellType,
					DecommissionDate: response.facilityInfo.facility.DecommissionDate
				})
			})
			.catch(e => {
				setDuplicateError(
					'A well with that DNR Well ID already exists for your agency.'
				)
			})
			.finally(() => setLoading(false))
	}, [DNRWellID])

	useEffect(() => {
		setFormDirty(dirty)
	}, [dirty])

	return (
		<div
			className={`columns is-multiline is-centered ${
				activePanel !== 'DNR Information' ? 'is-hidden' : ''
			}`}
		>
			<div className="column is-12">
				<form
					className="form"
					onSubmit={handleSubmit(() => checkDuplicateDNRID())}
				>
					<div className="is-size-4 has-text-centered matchingFacilityText">
						<span className="has-text-weight-semibold">
								DNR Information:
						</span>
					</div>
					<div className="columns is-vcentered is-centered">
						<div className="column is-12">
							<div className="field is-horizontal ">
								<div className="field-label is-align-items-center">
									<label className="label">DNR Well ID:</label>
								</div>
								<div className="field-body">
									<div className="field">
										<div className="control">
											<input
												disabled={viewOnly || isReadOnly}
												className="input is-fullwidth "
												name={'DNRWellID'}
												ref={formMethods.register}
												// value={alias}
												type="number"
												onChange={e => {
													const value = e.target.value
													setDNRWellID(value)
												}}
											/>
										</div>
										<span className="help has-text-grey">
											{dnrInfoHelperText}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					{!viewOnly && (
						<div className="columns is-centered is-multiline">
							<div className="column is-12 has-text-centered">
								{duplicateError ? (
									<div className="has-text-danger">
										{duplicateError}
									</div>
								) : error ? (
									<div className="has-text-danger">
										{error}
									</div>
								) : null
								}
							</div>
							<div className="column is-4 buttonWrapper">
								<div className="saveButtonWrapper">
									<button
										className={`button is-link is-medium ${
											loading ? 'is-loading' : ''
										}`}
										type="submit"
										disabled={error || viewOnly ||  isReadOnly}
									>
										Save
									</button>
								</div>
								<div className="saveButtonWrapper">
									<button
										type="button"
										className="button is-medium"
										onClick={() => {
											return formMethods.reset()
										}}
										disabled={viewOnly || isReadOnly}
									>
										{`Reset`}
									</button>
								</div>
							</div>
						</div>
					)}
				</form>
			</div>
		</div>
	)
})

export default DNRInformationTab
