import React, { useContext } from 'react'
import { DataContext } from '../DataContext'

export default () => {
    const { resetState } = useContext(DataContext)
    return (
        <div className="saveButtonWrapper">
            <button
                type="button"
                className="button is-medium"
                onClick={() => resetState()}
            >
                {`Reset`}
            </button>
        </div>
    )
}
