import React, { useContext } from 'react'
import { DataContext } from '../DataContext'

export default () => {
    const { isSubmitting } = useContext(DataContext)
    return (
        <div className="saveButtonWrapper">
            <button className={`button is-link is-medium ${isSubmitting ? 'is-loading' : ''}`} type="submit">
                Save
            </button>
        </div>
    )
}
