import React from 'react'

import DataContextProvider from '../../../explorer/map/DataContext'
import MapControlContextProvider from '../../../explorer/map/MapControlContext'
import Panel from '../../../../elem/panel/Panel'
import { mapPanel as panelClassConfig } from '../../../../../utils/styles/panelStyles'
import ViewMap from './ViewMap'
import WithConfig from '../../../../wrappers/withConfig'

export default WithConfig(() => (
    <DataContextProvider>
        <MapControlContextProvider mapMenu={`SubmissionsMap`}>
            <Panel panelClassConfig={panelClassConfig(12)}>
                <ViewMap />
            </Panel>
        </MapControlContextProvider>
    </DataContextProvider>
))
    