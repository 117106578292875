const well = {
    PageSize: 50,
    Page: 1,
}

const sample = {
    PageSize: 50,
    Page: 1,
}

const defaultParams = {
    well: { well, sample: {} },
    sample: { sample, well: {} },
    home: { well, sample },
    map: {},
}

export { defaultParams }
