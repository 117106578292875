import React from 'react'

const MapLayerLegendItem = ({ styleObj }) => {
    const style = JSON.parse(styleObj)
    return <div
            className="mapLayerLegendItem"
            style={{
                backgroundColor: style.fillColor,
                border: style.strokeColor
                    ? `solid ${style.strokeWidth  ? style.strokeWidth + 'px' : '1px'} ${style.strokeColor}`
                    : '',
                borderRadius: style.circle ? '10px' : null,
                height: style.size ? style.size : '18px',
                width: style.size ? style.size : '18px',
                minHeight: style.size ? style.size : '18px',
                minWidth: style.size ? style.size : '18px'
            }}
        />
}

const MapLayerLegend = ({ styleArray, type, expanded, shape }) => {
    const style = JSON.parse(styleArray)
    const borderRadius = shape === 'circle' ? '10px' : null
    if (!expanded) {
        return null
    }
    return (
        <div className="mapLayerLegendWrapper">
            {style.map(s => (
                <div className="mapLayerLegendItemWrapper">
                    {s.fillColor && s.fillColor.includes('.png') ? (
                            <img className="mapLayerLegendImage" alt="legend item" src={s.fillColor} />
                        ) : (
                            <div
                                className="mapLayerLegendItem"
                                style={{
                                    backgroundColor: s.fillColor,
                                    borderRadius,
                                    border: s.strokeColor
                                        ? `solid 1px ${s.strokeColor}`
                                        : '',
                                }}
                            />
                        )}
                    <div className="mapLayerLegendItemText is-size-7">
                        {type === 'THEME' && s.range}
                        {type === 'COLORMAP' && s.label}
                        {type === 'SPECTRUM' && s.label}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default MapLayerLegendItem
export { MapLayerLegend, MapLayerLegendItem }
