import React, { useContext } from 'react'

import PanelHeader from '../../../elem/panel/PanelHeader'
import { DataContext} from './DataContext'
import TimeWindowSelectorButton from '../../../elem/chart/TimeWindowSelectorButton'
import { IconContext } from 'react-icons'
import { IconTooltip as Tooltip } from '../../../elem/Tooltip'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'
import ResetZoomButton from '../../../elem/chart/ResetZoomButton'

// import { IconTooltup as Tooltip } from '../../../elem/Tooltip'

export default () => {
    const {
        timeWindow,
        displayTimeWindowDropdown,
        toggleTimeWindowDropdown,
        isCollapsed,
        toggleCollapsed,
        resetZoom,
    } = useContext(DataContext)

    return (
        <PanelHeader extraClass={'aggregatePanelHeader'}>
            <div className="panelHeaderText">Aggregate Nitrate Chart</div>
            <div className="panelButtons">
                <ResetZoomButton resetFunction={resetZoom} />
                <TimeWindowSelectorButton
                    timeWindow={timeWindow}
                    displayTimeWindowDropdown={displayTimeWindowDropdown}
                    toggleTimeWindowDropdown={toggleTimeWindowDropdown}
                />
                <div
                    className={`panelHeaderIconDiv displayIcon`}
                    data-for={'displayCollapse'}
                    data-tip={ `Collapse / Expand` }
                >
                    <IconContext.Provider
                        value={{
                            className: `panelHeaderIcon ${
                                isCollapsed ? 'active' : 'inactive'
                            }`,
                        }}
                    >
                        { isCollapsed ? 
                            <IoIosArrowUp onClick={ () => toggleCollapsed() } /> 
                                : 
                            <IoIosArrowDown onClick={ () => toggleCollapsed() }/> 
                        }
                    </IconContext.Provider>
                    <Tooltip id={'displayCollapse'} />
                </div>
                {/* TODO: Implement time window selector */}
            </div>
        </PanelHeader>
    )
}
