import React from 'react'
import { Link } from 'react-router-dom'
import { FaCheck, FaTimes } from 'react-icons/fa'
import moment from 'moment/moment'

const parseColumns = (data, dateColumns) => {
    if (data && data.length) {
        const exampleRow = data[0]
        const columns = Object.keys(exampleRow).map(col => {
            const isDate = dateColumns.indexOf(col) !== -1
            const sortType = isDate ? 'datetime' : 'alphanumeric'
            let returnColumn = !isDate ? ({
                Header: () => <div>{col}</div>,
                accessor: col,
                Cell: ({ cell }) => (<div>{cell.value}</div>),
            }) : ({
                Header: () => <div>{col}</div>,
                id: col, 
                accessor: row => {
                    let returnDate
                    const momentDate = moment(row[col])
                    if (momentDate.isValid()) 
                        returnDate = momentDate.toDate()
                    else
                        returnDate = moment('1/1/1002').toDate() 

                    return returnDate 
                },
                Cell: ({ cell }) => 
                    moment(cell.value).format('MM/DD/YYYY hh:mm:ss A').includes('01/01/1002') 
                        ? '' : moment(cell.value).format('MM/DD/YYYY hh:mm:ss A'), //MM/DD/YYYY hh:mm:ss A ... see hacky solution up there
                sortType
            })
            return returnColumn
        })
        return columns
    } else {
        return []
    }
}

const parseColumnsWithConfig = (data, config) => {
    if (data && data.length && config && config.length) {
        const exampleRow = data[0]
        const columns = Object.keys(exampleRow).map(col => {
            let columnName = col
            const configObj = config.find(x => x.ColumnName === col)
            if (configObj) {
                columnName = configObj.ColumnTitle
            }
            return {
                Header: () => <div>{columnName}</div>,
                accessor: col,
                Cell: ({ cell }) => {
                    let value = cell.value
                    if (configObj) {
                        if (configObj.Values) {
                            let configValue = configObj.Values.find(x => x.code != null && value != null ? x.code === value || x.code.toString() === value.toString() : null)
                            value = configValue && configValue.codedescription  ? configValue.codedescription : null
                        }
                    }
                    return <div>{value}</div>
                },
            }
        })
        return columns
    } else {
        return []
    }
}

const getInProgressColumns = (data, setConfirmDeleteModalData) => {
    const columns = parseColumns(data, ['Modify Date']).filter(
        x => x.accessor !== 'Link' && x.accessor !== 'IsValid'
    )
    
    return [
        {
            Header: () => <div>Validation Status</div>,
            accessor: 'IsValid',
            Cell: ({ cell }) => {
                const isValid = cell.row.values.IsValid === 'Pass'
                return (
                    <div>
                        {isValid ? (
                            <span className="icon-text">
                                <span className="icon">
                                    <i className="icon">
                                        <FaCheck className="has-text-success" />
                                    </i>{' '}
                                </span>
                                <span>Pass </span>
                            </span>
                        ) : (
                            <span className="icon-text">
                                <span className="icon">
                                    <i className="icon">
                                        <FaTimes className="has-text-danger" />
                                    </i>{' '}
                                </span>
                                <span>Fail </span>
                            </span>
                        )}
                    </div>
                )
            },
        },
        ...columns,
        {
            Header: () => <div>Actions</div>,
            accessor: 'nothing',
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ cell }) => {
                const recordValues = cell.row.values
                return (
                    <div>
                        <Link
                            to={{
                                pathname: recordValues["Is EDD?"] === 'Yes' && recordValues['Form Type'] === 'Sample Result EDD'
                                    ? `submissions/edd/${recordValues[`Form ID`]}`
                                    : `submissions/form/${recordValues[`Form ID`]}`
                                    // state: getCellGSAIDObject(cell)
                            }}
                        >
                    Edit
                        </Link>
                        {` | `}
                        <div
                            className="link inline-block"
                            onClick={() =>
                                setConfirmDeleteModalData({ 
                                    display: true,
                                    uploadId: recordValues['Form ID'],
                                    formType: recordValues['Form Type'],
                                    submittingEDD: recordValues['Submitting EDD'],
                                })
                            }
                        >
                            Delete
                        </div>
                    </div>
                )
            },
        },
    ]
}

const getSubmittedColumns = (data, isReviewer) => {
    const columns = parseColumns(data, ["Submitted Date", "Approved / Denied Date"]).filter(x => x.accessor !== 'Link')
    return [
        ...columns,
        {
            Header: () => <div>Actions</div>,
            accessor: 'none',
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ cell }) => {
                const recordValues = cell.row.values
                return (
                    <div>
                        <Link 
                            to={{
                                pathname: recordValues["Is EDD?"] === 'Yes' && recordValues['Form Type'] === 'Sample Result EDD'
                                    ? `submissions/edd/view/${recordValues[`Form ID`]}`
                                    : `submissions/form/view/${recordValues[`Form ID`]}`,
                                state: { clearinghouse: recordValues["Clearinghouse Number"] }  
                            }}
                        >
                            View
                        </Link>
                        {isReviewer && recordValues.Status === "Submitted" && (
                            <>
                                {` | `}
                                <Link
                                    to={{
                                        pathname: recordValues["Is EDD?"] === 'Yes' && recordValues['Form Type'] === 'Sample Result EDD'
                                            ? `submissions/edd/review/${recordValues[`Form ID`]}`
                                            : `submissions/form/review/${recordValues[`Form ID`]}`,
                                        state: { clearinghouse: recordValues["Clearinghouse Number"] }  
                                    }}
                                >
                                    Review
                                </Link>
                            </>
                        )}
                    </div>
                )
            },
        },
    ]
}

const getEmptyColumnObject = columns => {
    return columns.reduce((acc, curr) => {
        const field = curr.accessor
        return {
            ...acc,
            [field]: null,
        }
    }, {})
}

export {
    getInProgressColumns,
    getSubmittedColumns,
    parseColumns,
    getEmptyColumnObject,
    parseColumnsWithConfig,
}
