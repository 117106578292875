import React, { useContext, useEffect, useMemo, useState } from 'react'

import EditableTable from '../../../elem/table/EditableTable'
import { parseUploadColumns } from '../../../../utils/table/parseColumns'
import { DataContext } from '../DataContext'
import SectionWrapper from './SectionWrapper'
import GeneralErrors from './GeneralErrors'
import deepClone from 'lodash.clonedeep'
import PageErrorText from './PageErrorText'
import { useFormContext } from 'react-hook-form'
import ConfirmDeleteRecord from './ConfirmDeleteRecord'
import deleteFunction from '../table/deleteFunction'

const getInputNameFromConfig = (config, fieldName) => {
    if (!config) {
        return fieldName
    }
    const name = config.ColumnName
    switch (config.ControlType) {
        case 'TextBox':
            return name
        case 'Select':
            return `${name}Select`
        case 'MultiSelect':
            return `${name}Select`
        case 'DateDayPicker':
            return `${name}DateSelect`
        case 'DatePicker':
            return `${name}DateSelect`
        case 'Creatable':
            return `${name}Select`
        default:
            return name
    }
}

const getDataFromSubmissionState = (submissionState, accessor) =>
    submissionState && submissionState[accessor]
        ? submissionState[accessor]
        : []

export default ({ config, accessor, title, subtitle }) => {
    const {
        submissionState,
        formMethods,
        viewOnly,
        setTableData,
        setFormDirty,
        updateControlledPageSize,
        pageErrorText,
        beforePageChange,
        displayDeleteModal,
        setDisplayDeleteModal,
        removeAndShiftErrorState,
    } = useContext(DataContext)

    const { setValue } = useFormContext()

    const [data, setData] = useState(getDataFromSubmissionState(submissionState, accessor))
    
    const columns = useMemo(
        () => 
            parseUploadColumns(
                [],
                config,
                submissionState,
                viewOnly,
                setDisplayDeleteModal
            )
        , [config, formMethods, accessor, submissionState, viewOnly]
    )

    useEffect(() => {
        setData(getDataFromSubmissionState(submissionState, accessor))
    }, [config, submissionState])

    useEffect(() => {
        if (data.length) {
            const lastRowIdx = data.length - 1
            const lastRow = data[lastRowIdx]
            if (!Object.keys(lastRow).some(x => lastRow[x] !== null)) {
                // TODO: When we add a row, make sure the form data is cleared
                Object.keys(lastRow).map(key => {
                    const associatedConfig = config.find(x => x.ControlName === key)
                    const fieldName = getInputNameFromConfig(associatedConfig, key) 
                    const formName = `${accessor}[${lastRowIdx}].${fieldName}`

                    // hack for the id keys in dependent tables
                    if (formName.includes('ID') && !formName.includes('Select')) {
                        setValue(formName.replace('ID', 'IDSelect'), null)   
                    }
                    return setValue(formName, null)
                })
            }
        }
    }, [data, submissionState, accessor, setValue, config])


    useEffect(() => {
        setTableData(prevTableData => {
            const newTableData = deepClone(prevTableData)
            newTableData[accessor] = data
            return newTableData
        })
    }, [data, accessor])
    
    const boundDeleteFunction = useMemo(() => 
        deleteFunction.bind(
            this,
            setData,
            formMethods,
            accessor,
            setFormDirty,
            columns.configsWithDependentFields,
            removeAndShiftErrorState,
        ), [
        setData,
        formMethods,
        accessor,
        setFormDirty,
        columns,
        removeAndShiftErrorState
    ])

    const tableData = useMemo(() => data, [data])
    return (
        <SectionWrapper title={title} subtitle={subtitle}>
            <PageErrorText string={pageErrorText[accessor]} />
            <GeneralErrors />
            <div className="formTableWrapper">
                <ConfirmDeleteRecord
                    displayDeleteModal={displayDeleteModal}
                    setDisplayDeleteModal={setDisplayDeleteModal}
                    accessor={accessor}
                    deleteFunction={boundDeleteFunction}
                />
                <EditableTable
                    columns={columns.returnColumns}
                    data={tableData}
                    setData={!viewOnly ? setData : null}
                    setControlledPageSize={updateControlledPageSize.bind(this, accessor)}
                    beforePageChange={beforePageChange}
                />
            </div>
        </SectionWrapper>
    )
}
