const urlMap = {
    home: '/',
    sample: '/sample-explorer',
    well: '/well-explorer',
    map: '/map',
    userCallback: '/user/callback',
    newRegistration: `${process.env.REACT_APP_OIDC_REGISTRATION_URL}`,
    changePassword: `${process.env.REACT_APP_OIDC_CHANGE_PASSWORD_URL}`,
    manageSampleResults: `/submissions`,
    uploadDialogue: '/submissions/new',
    edd: '/submissions/edd',
    wellEDD: '/submissions/edd/wellEDD',
    viewEDD: '/submissions/edd/view/:uploadId',
    reviewEDD: '/submissions/edd/review/:uploadId',
    form: '/submissions/form',
    editForm: '/submissions/form/:uploadId',
    viewForm: '/submissions/form/view/:uploadId',
    reviewForm: '/submissions/form/review/:uploadId',
    manageUsers: `${process.env.REACT_APP_USER_MANAGEMENT_URL}`,
    deactivateUser: '/user/deactivate/:userId',
    denyUser: '/user/deny/:userId'
}

const urls = urlMap

const mapUrlToName = Object.keys(urlMap).reduce((acc, cur) => {
    const name = urlMap[cur]
    return {
        ...acc,
        [name]: cur
    }
}, {})

const isPageExplorer = (location) => {
    const explorerUrls = [urls.home, urls.sample, urls.well]
    return explorerUrls.includes(location.pathname)
}

export { urls, mapUrlToName, isPageExplorer }
export default urls