import React, { createContext, useContext, useState, useEffect, useCallback } from 'react'

import withConfig from '../../../wrappers/withConfig'
import { AppStateContext } from '../AppStateContext'
import { ParameterContext } from '../../../wrappers/ParameterContext'
import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import { timeWindowOptions } from '../../../../utils/chart/timeWindow'
import toast from '../../../elem/Toast'

const DataContext = createContext(null)

// const generateDateParams = (params) => {
//     const dates = (params.sample && params.sample.SimpleSampleDateValue) ? decodeURIComponent(params.sample.SimpleSampleDateValue).split(',') : []
//     if (!(dates.length)) {
//         return [null, null]
//     }
//     const startDate = dates[0]
//     const endDate = dates.length > 1 ? dates[1] : null
//     return [startDate, endDate]
// }

const generateDateParams = (params, explorer, fieldName) => {
    const paramDate = (params[explorer] && params[explorer][`${fieldName}Value`]) 
        ? decodeURIComponent(params[explorer][`${fieldName}Value`]).split(',') 
        : []
    const dates = {
        fieldName: fieldName,
        startDate: paramDate.length > 0? paramDate[0] : null,
        endDate: paramDate.length > 1 ? paramDate[1] : null,
        operator: decodeURIComponent(params[explorer][`${fieldName}Operator`]),
    }
    return dates
}

export default withConfig(({ config, children }) => {
    const { mapState } = useContext(AppStateContext)
    const { params } = useContext(ParameterContext)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [chartData, setChartData] = useState([])
    const [tooManyFeatures, setTooManyFeatures] = useState(false)
    const [zoomTrigger, setZoomTrigger] = useState(false)
    const [timeWindow, setTimeWindow] = useState(timeWindowOptions[0])
    const [displayTimeWindowDropdown, toggleTimeWindowDropdown] = useState(false)
    const [loading, setLoading] = useState(false)
    const [summaryLoading, setSummaryLoading] = useState(false)
    const [isCollapsed, setCollapsed] = useState(false)
    const { selectedFeatures } = mapState
    const { API_URL } = config
    const [summaryMedianData, setSummaryMedianData] = useState([])

    const toggleCollapsed = () => setCollapsed(!isCollapsed)
    
    const fetchData = selectedFeatures => {
        setLoading(true)
        setTooManyFeatures(false)

        const dates = generateDateParams(params, `sample`, `SimpleSampleDate`)
        const paramList = encodeURI(selectedFeatures.map(x => x.get('ClearinghouseNumber')).toString())
        
        const body = JSON.stringify({
            ids: paramList,
            Dates: dates,
        })
        authenticatedFetch(`${API_URL}/aggregate`
        , {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept'
                },
            body
        })
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                setChartData(response.chartData)
            })
            .catch(e => {
                toast({
                    level: 'error',
                    message:
                        'Sample List: ' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.'),
                })
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (selectedFeatures.length) {
            // SQL Server has a limit of 2100 parameters that can be supplied
            // to a query. if there is a workaround, this check can be removed
            if (selectedFeatures.length < 2100) {
            resetZoom() // if data changes set the map boundaries to dataMin-Max in the chart 
            fetchData(selectedFeatures)
            } else {
                setTooManyFeatures(true)
            }
        } else {
            // if there are no features,
            // set data to an empty array
            setChartData([])
        }
    }, [selectedFeatures])

    const resetZoom = useCallback(() => {
        setZoomTrigger(!zoomTrigger)
    }, [zoomTrigger])

    const getSummaryMedianData = (selectedFeatures, zoomStartDate, zoomEndDate) => {
        setSummaryLoading(true)
        setTooManyFeatures(false)
        let dates
        if (zoomStartDate && zoomEndDate) {
            dates = {
                fieldName: `SimpleSampleDate`,
                startDate: zoomStartDate, 
                endDate: zoomEndDate,
                operator: '>=,<='
            }
        } else {
            dates = generateDateParams(params, `sample`, `SimpleSampleDate`)
        }

        const paramList = encodeURI(selectedFeatures.map(x => x.get('ClearinghouseNumber')).toString())
        const body = JSON.stringify({
            ids: paramList,
            Dates: dates,
        })
        authenticatedFetch(`${API_URL}/aggregate/GetMedianSummaryData`
        , {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept'
                },
            body
        })
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                setSummaryMedianData(response.medianSummaryData)
            })
            .catch(e => {
                toast({
                    level: 'error',
                    message:
                        'Sample List: ' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.'),
                })
            })
            .finally(() => setSummaryLoading(false))
    }

    return (
        <DataContext.Provider
            value={{
                loading,
                chartData,
                tooManyFeatures,
                timeWindow,
                setTimeWindow,
                displayTimeWindowDropdown, 
                toggleTimeWindowDropdown,
                resetZoom,
                zoomTrigger,
                isCollapsed,
                setCollapsed,
                toggleCollapsed,
                getSummaryMedianData,
                summaryMedianData,
                summaryLoading,
            }}
        >
            {children}
        </DataContext.Provider>
    )
})

export { DataContext }