import React, { useEffect, useState } from 'react'

import { SimpleSpinner } from '../elem/Spinner'

export default ({data, children }) => {
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        if (data) {
            setIsLoading(false)
        }
    }, [data])

    if (isLoading) {
        return <SimpleSpinner />
    } else {
        return children
    }
}