import React, { useContext, useMemo } from 'react'
import {
    FaLayerGroup,
    FaChevronUp,
    FaChevronDown,
    FaInfoCircle,
    FaSearchPlus
} from 'react-icons/fa'

import { MapControlContext } from '../MapControlContext'
import { PanelStyleContext } from '../../../../elem/panel/PanelStyleContext'

import { MapLayerLegendItem, MapLayerLegend } from './MapLayerLegend'
import Tooltip from '../../../../elem/Tooltip'

const wellLegendStyles = [
    { fillColor: '#9C9C9C', range: 'No Nitrate Results'},
    { fillColor: '#006100', range: '0.00 - 0.99' },
    { fillColor: '#559100', range: '1.00 - 2.49' },
    { fillColor: '#E6E600', range: '2.50 - 4.99' },
    { fillColor: '#FFAA00', range: '5.00 - 7.49' },
    { fillColor: '#FF5500', range: '7.50 - 9.99' },
    { fillColor: '#A80084', range: '10.00 - 19.99' },
    { fillColor: '#4C0073', range: '> 19.99' },
]

const hasDropdowns = featureType =>
    !!['THEME', 'COLORMAP', 'SPECTRUM'].find(x => x === featureType)

export default ({ mapMenu }) => {
    const { displayMapLayers, layerState, setLayerState } = useContext(
        MapControlContext
    )
    const { borderClass, tooltipClass } = useContext(PanelStyleContext)

    if (!displayMapLayers) {
        return null
    }
    const layerStateProps = useMemo(() => 
        Object.keys(layerState).map(key => layerState[key])
    , [layerState])

    const layerGroups = [
        ...new Set(
            layerStateProps
                .filter(x => mapMenu ? x.MapMenu === mapMenu : true)
                .map(x => x.layerGroupName)
        ),
    ]
        .sort()
        .reverse()

    const flatLayerState = 
        layerStateProps
            .filter(x => mapMenu ? x.MapMenu === mapMenu : true)

    return (
        <div className="mapLayerControlsPositioning">
            <div className="mapLayerControlsPadding">
                <div className={`mapLayerControlsWrapper ${borderClass}`}>
                    <div className="mapLayerControlsHeader">
                        <div className="mapLayerControlsTitle">
                            <FaLayerGroup className="mapLayerIcon" />
                            <span className="mapLayerControlsText">Layers</span>
                        </div>
                    </div>
                    <hr className={`${borderClass} mapLayerControlsDivider`} />
                    <div className="mapLayerControlsContent">
                        {layerGroups.map(layerGroupName => {
                            const associatedLayers = flatLayerState.filter(
                                l => l.layerGroupName === layerGroupName
                            )
                            return (
                                <div
                                    className="layerGroupWrapper"
                                    key={`layer-group-${layerGroupName}`}
                                >
                                    <div className="layerGroupName">
                                        {layerGroupName}
                                    </div>
                                    {associatedLayers.map(layer => {
                                        const {
                                            display,
                                            expanded,
                                            unique,
                                            layerGroupName,
                                            LayerName: name,
                                            StyleObj: styleObj,
                                            StyleArray: styleArray,
                                            shape,
                                            Info: helper,
                                            LayerType: layerType,
                                            MinZoom: minZoom,
                                        } = layer
                                        let styles = styleObj
                                        
                                        // find out if layer should have dropdowns
                                        // eg is of type COLORMAP, SPECTRUM, or THEME
                                        const layerHasDropdown = hasDropdowns(
                                            layerType
                                        )
                                        
                                        // THEME layer has a different name for the column associated w/ styles
                                        if (layerType === 'THEME') {
                                            styles = styleArray
                                        }
                                        return (
                                            <React.Fragment
                                                key={`layer-control-${name}`}
                                            >
                                                <div className="layerControlWrapper">
                                                    <div className="control">
                                                        <input
                                                            className="checkbox"
                                                            type="checkbox"
                                                            checked={display}
                                                            readOnly
                                                            onClick={() => {
                                                                setLayerState(
                                                                    previousLayerState => {
                                                                        let newLayerState = previousLayerState
                                                                        // if there is a 'unique' requirement on
                                                                        // the layer group, then disable the
                                                                        // other layers first
                                                                        if (
                                                                            unique
                                                                        ) {
                                                                            newLayerState = Object.keys(
                                                                                newLayerState
                                                                            ).reduce(
                                                                                (
                                                                                    acc,
                                                                                    curr
                                                                                ) => {
                                                                                    if (
                                                                                        newLayerState[
                                                                                            curr
                                                                                        ]
                                                                                            .layerGroupName ===
                                                                                        layerGroupName
                                                                                    ) {
                                                                                        return {
                                                                                            ...acc,
                                                                                            [curr]: {
                                                                                                ...newLayerState[
                                                                                                    curr
                                                                                                ],
                                                                                                display: false,
                                                                                            },
                                                                                        }
                                                                                    } else {
                                                                                        return {
                                                                                            ...acc,
                                                                                            [curr]: {
                                                                                                ...newLayerState[
                                                                                                    curr
                                                                                                ],
                                                                                            },
                                                                                        }
                                                                                    }
                                                                                },
                                                                                {}
                                                                            )
                                                                        }
                                                                        return {
                                                                            ...newLayerState,
                                                                            [name]: {
                                                                                ...newLayerState[
                                                                                    name
                                                                                ],
                                                                                display: !display,
                                                                            },
                                                                        }
                                                                    }
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                    {layerType === 'FEATURE' &&
                                                    styles ? (
                                                        <MapLayerLegendItem
                                                            styleObj={styles}
                                                        />
                                                    ) : null}
                                                    <div
                                                        className={`layerControlText is-size-7 ${
                                                            layerHasDropdown &&
                                                            styleObj
                                                                ? 'clickable'
                                                                : ''
                                                        }`}
                                                        onClick={() => {
                                                            if (
                                                                layerHasDropdown &&
                                                                styles
                                                            ) {
                                                                setLayerState(
                                                                    previousLayerState => ({
                                                                        ...previousLayerState,
                                                                        [name]: {
                                                                            ...previousLayerState[
                                                                                name
                                                                            ],
                                                                            expanded: !expanded,
                                                                        },
                                                                    })
                                                                )
                                                            }
                                                        }}
                                                    >
                                                        {name}
                                                        <div className="layerControlIcons">
                                                            {helper && (
                                                                <div
                                                                    data-tip={`${helper}`}
                                                                    data-for={name}
                                                                    className="formHelper inline"
                                                                >
                                                                    <span className="is-static is-small">
                                                                        <FaInfoCircle />
                                                                        <Tooltip
                                                                            id={
                                                                                name
                                                                            }
                                                                            className={
                                                                                tooltipClass
                                                                            }
                                                                        />
                                                                    </span>
                                                                </div>
                                                            )}
                                                            { minZoom && (
                                                                <div
                                                                    data-tip={`Zooming in is required to see this layer.`}
                                                                    data-for={`zoom-${name}`}
                                                                    className="formHelper inline"
                                                                >
                                                                    <span className="is-static is-small">
                                                                        <FaSearchPlus />
                                                                        <Tooltip
                                                                            id={
                                                                                `zoom-${name}`
                                                                            }
                                                                            className={
                                                                                tooltipClass
                                                                            }
                                                                        />
                                                                    </span>
                                                                </div>
                                                            )}
                                                            {layerHasDropdown ? (
                                                                <div
                                                                    className="formHelper inline vertically-centered"
                                                                >
                                                                    <span className="is-static is-small">
                                                                        {expanded ? (
                                                                            <FaChevronUp className="mapLayerChevron" />
                                                                        ) : (
                                                                            <FaChevronDown className="mapLayerChevron" />
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                {layerHasDropdown && styles ? (
                                                    <MapLayerLegend
                                                        styleArray={styles}
                                                        expanded={expanded}
                                                        type={layerType}
                                                        shape={shape}
                                                    />
                                                ) : null}
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export { wellLegendStyles }
