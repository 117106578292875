import React, { createContext, useContext, useState, useEffect, useCallback, } from 'react'

import toast from '../../../elem/Toast'
import withConfig from '../../../wrappers/withConfig'
import { AppStateContext } from '../AppStateContext'
import { timeWindowOptions } from '../../../../utils/chart/timeWindow'

const DataContext = createContext(null)

const DataContextProvider = ({ config, children }) => {
    // get the detail id from the app state context 
    const { detailState } = useContext(AppStateContext)
    const { well: wellDetailState } = detailState
    const clearinghouseNumber = wellDetailState.clearinghouseNumber

    // get the API_URL from config
    const { API_URL } = config

    // loading state
    const [isLoading, setLoading] = useState(false)

    // data state
    const [detailData, setDetailData] = useState({})
    const [filterData, setFilterData] = useState({})
    const [visibleTab, setVisibleTab] = useState('well')

    // display state
    const [timeWindow, setTimeWindow] = useState(timeWindowOptions[0])
    const [displayTimeWindowDropdown, toggleTimeWindowDropdown] = useState(false)
    
    // fetch data on clearinghouseNumber change
    const fetchData = () => {
        if (clearinghouseNumber) {
            setDetailData({})
            fetch(`${API_URL}/well/${clearinghouseNumber}`)
                .then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(response => {
                    setDetailData(response.data)
                    setFilterData(response.filterData)
                })
                .catch(e => {
                    toast({
                        level: 'error',
                        message:
                            'Well Detail: ' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server. Please try again later.'),
                    })
                })
                .finally(() => setLoading(false))
        }
    }

    useEffect(() => {
        resetZoom() // if data changes set the map boundaries to dataMin-Max in the chart 
        fetchData(clearinghouseNumber)
    }, [clearinghouseNumber])

    const [zoomTrigger, setZoomTrigger] = useState(false)

    const resetZoom = useCallback(() => setZoomTrigger(!zoomTrigger), [zoomTrigger])

    return (
        <DataContext.Provider
            value={{
                clearinghouseNumber,
                isLoading,
                detailData,
                filterData,
                visibleTab,
                timeWindow,
                displayTimeWindowDropdown, 
                toggleTimeWindowDropdown,
                setTimeWindow,
                setVisibleTab,
                resetZoom,
                zoomTrigger,
            }}
        >
            {children}
        </DataContext.Provider>
    )
}

export { DataContext }
export default withConfig(DataContextProvider)
