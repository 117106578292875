import React, { useContext } from 'react'

import AgencyConfirmation from '../AgencyConfirmation'
import SubmissionForm from './SubmissionForm'
import { DataContext } from '../DataContext'
import LoadingWrapper from '../../../wrappers/LoadingWrapper'

export default () => {
    const { activeAgency, createUpload, isForminProgress } = useContext(DataContext)
    
    return isForminProgress ? 
        <LoadingWrapper data={activeAgency} >
            <SubmissionForm /> 
        </LoadingWrapper> 
            : 
        <AgencyConfirmation onSubmit={ createUpload } /> 
}
