import React, { createContext, useContext, useState, useEffect, useCallback } from 'react'

import withConfig from '../../../wrappers/withConfig'
import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import actions from '../../../../utils/submissions/actions'
import { UserContext } from '../../../wrappers/UserContext'
import toast from '../../../elem/Toast'

const TableContext = createContext(null)

const TableContextProvider = ({ config, children }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { user } = useContext(UserContext)
    const [loading, setLoading] = useState(true)
    const [uploads, setUploads] = useState(null)
    const [selectedUploads, setSelectedUploads] = useState([])
    const { API_URL, UPLOAD_ID_COLUMN } = config
    const [confirmDeleteModalData, setConfirmDeleteModalData] = useState({ display: false, uploadId: null, submittingEDD: null })

    // fetch uploads
    useEffect(() => {
        actions.getTableData(authenticatedFetch, API_URL, setLoading, setUploads)
    }, [])

    // bulk submit
    const bulkSubmit = useCallback(() => { 
        const allSelectedUploadsValid = selectedUploads.every(x => x.IsValid === "Pass")
        if (allSelectedUploadsValid) {
            const selectedUploadIds = selectedUploads.map(x => x['Form ID'])
            actions.bulkSubmit(authenticatedFetch, API_URL, selectedUploadIds, user.profile.name, () => {
                // alert the user that upload was successful
                toast({
                    level: 'info',
                    message: 'All selected forms have been submitted and can now be viewed in the Submitted Forms tab',
                    alert: true
                })
                // refetch table data
                actions.getTableData(authenticatedFetch, API_URL, setLoading, setUploads)
            })
        } else {
            toast({
                level: 'error',
                message:
                    'All selected forms must have Validation Status of Pass in order to submit',
                alert: true,
            })
        }
    }, [selectedUploads, UPLOAD_ID_COLUMN, user])
    
    return (
        <TableContext.Provider 
        value={{
            uploads,
            setUploads,
            selectedUploads,
            setSelectedUploads,
            loading,
            confirmDeleteModalData,
            setConfirmDeleteModalData,
            bulkSubmit
        }}>
            {children}
        </TableContext.Provider>
    )
}

export { TableContext }
export default withConfig(TableContextProvider)
