import React, { useMemo } from 'react'

import Modal from '../../../elem/Modal'

export default ({ displayDeleteModal, setDisplayDeleteModal, deleteFunction, accessor }) => {
    const sectionAccessor = useMemo(
        () =>
            displayDeleteModal && displayDeleteModal.sectionConfig 
            && Array.isArray(displayDeleteModal.sectionConfig) && displayDeleteModal.sectionConfig[0].DataAccessor
    , [displayDeleteModal])

    return (
        <Modal
            display={ sectionAccessor === accessor ? displayDeleteModal.display : false }
            confirmAction={() => {
                        deleteFunction(displayDeleteModal.tableProps)
                        setDisplayDeleteModal({ ...displayDeleteModal, display: false})
                    }
                } 
            confirmText={`Delete`}
            denyAction={() => setDisplayDeleteModal({ ...displayDeleteModal, display: false})}
            denyText={`Cancel`}
            title={'Delete Confirmation'}
        >
            Are you sure you want to delete this record?
        </Modal>
    )
}