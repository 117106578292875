import React, { useContext, useEffect } from 'react'
import { IconContext } from 'react-icons'
import {
    FaInfoCircle, FaLayerGroup,
} from 'react-icons/fa'

import PanelHeader from '../../../../elem/panel/PanelHeader'
import { IconTooltip as Tooltip } from '../../../../elem/Tooltip'
import { MapControlContext } from '../../../explorer/map/MapControlContext'

export default () => {
    const {
        displayTooltip,
        toggleTooltip,
        displayMapLayers,
        toggleMapLayers,
    } = useContext(MapControlContext)

    useEffect(() => toggleTooltip(true), [])
    
    return (
        <PanelHeader extraClass={`mapPanelHeader`}>
            <div className="mapHeaderText">
                Map
            </div>
        <div className="panelHeaderControls">
            <div className="panelHeaderIconWrapper">
                <div
                    className={`panelHeaderIconDiv displayIcon ${
                        displayTooltip ? 'active' : 'inactive'
                    }`}
                    data-for={'displayTooltip'}
                    data-tip={'Display / Hide Tooltip'}
                >
                    <IconContext.Provider
                        value={{
                            className: `panelHeaderIcon ${
                                displayTooltip ? 'active' : 'inactive'
                            }`,
                        }}
                    >
                        <FaInfoCircle
                            onClick={() => {
                                toggleTooltip(!displayTooltip)
                            }}
                        />
                    </IconContext.Provider>
                    <Tooltip id={'displayTooltip'} />
                </div>
                <div
                    className="panelHeaderIconDiv"
                    data-for={'displayLayers'}
                    data-tip={'Display / Hide Layers'}
                >
                    <IconContext.Provider
                        value={{ className: 'panelHeaderIcon' }}
                    >
                        <FaLayerGroup
                            onClick={() => {
                                toggleMapLayers(!displayMapLayers)
                            }}
                        />
                    </IconContext.Provider>
                    <Tooltip id={'displayLayers'} />
                </div>
            </div>
        </div>
        </PanelHeader>
    )
}