import React, { useContext } from 'react'
import { IconContext } from 'react-icons'
import {
    FaMousePointer,
    FaTimesCircle,
    FaVectorSquare,
    FaDrawPolygon,
    FaInfoCircle,
    FaLayerGroup,
    FaFilter,
    FaTimes,
} from 'react-icons/fa'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'

import { IconTooltip as Tooltip } from '../../../elem/Tooltip'
import { MapControlContext } from './MapControlContext'
import { DataContext } from './DataContext'
import { AppStateContext } from '../AppStateContext'
import { PanelStyleContext } from '../../../elem/panel/PanelStyleContext'
import withConfig from '../../../wrappers/withConfig'

const MapHeaderControls = ({ config }) => {
    const {
        mapState: { selectedFeatures, mapSelectionIds },
        setMapState,
    } = useContext(AppStateContext)
    const { backgroundColorClass, borderClass, headerIconClass } = useContext(
        PanelStyleContext
    )
    const {
        activeControl,
        activateControl,
        clearControls,
        displayTooltip,
        toggleTooltip,
        displayMapLayers,
        toggleMapLayers,
        isCollapsed,
        toggleCollapsed
    } = useContext(MapControlContext)
    const { clearSelectionLayer } = useContext(DataContext)
    const { ID_COLUMN } = config

    const controls = [
        {
            icon: FaMousePointer,
            name: 'select',
            tooltip: 'Select Wells by Click',
        },
        {
            icon: FaVectorSquare,
            name: 'boundingBox',
            tooltip: 'Select Wells by Rectangle',
        },
        {
            icon: FaDrawPolygon,
            name: 'polygon',
            tooltip: 'Select Wells by Polygon',
        },
    ]

    return (
        <div className="panelHeaderControls">
            <div className="panelHeaderIconWrapper">
                <button
                    disabled={!(selectedFeatures && selectedFeatures.length)}
                    className={`button is-small panelHeaderIconDiv ${borderClass} ${backgroundColorClass} ${headerIconClass} displayIcon`}
                    // data-tip={'Filter by Map Selection'}
                    // data-for={`filterByMapSelection`}
                    onClick={() => {
                        setMapState(prevState => ({
                            ...prevState,
                            mapSelectionIds: selectedFeatures.map(x => x.get(ID_COLUMN)).join(',')
                        }))
                    }}
                >
                    <span className="icon is-small">
                        <IconContext.Provider
                            value={{
                                className: `panelHeaderIcon inactive`,
                            }}
                        >
                            <FaFilter />
                        </IconContext.Provider>    
                    </span>
                    <span>
                        Filter by Map Selection
                    </span>
                    {/* <Tooltip id={`filterByMapSelection`} /> */}
                </button>
                <button
                    disabled={mapSelectionIds === null || mapSelectionIds === ""}
                    className={`button is-small panelHeaderIconDiv ${borderClass} ${backgroundColorClass} ${headerIconClass} displayIcon`}
                    // data-tip={'Filter by Map Selection'}
                    // data-for={`filterByMapSelection`}
                    onClick={() => {
                        setMapState(prevState => ({
                            ...prevState,
                            mapSelectionIds: "",
                        }))
                    }}
                >
                    <span className="icon is-small">
                        <IconContext.Provider
                            value={{
                                className: `panelHeaderIcon inactive`,
                            }}
                        >
                            <FaTimes />
                        </IconContext.Provider>    
                    </span>
                    <span>
                        Remove Map Filter
                    </span>
                    {/* <Tooltip id={`filterByMapSelection`} /> */}
                </button>
                <div className="controlsGroupedWrapper">
                    {controls.map((control, idx) => {
                        const { name, icon: Icon, tooltip } = control
                        return (
                            <div
                                key={`icon-${idx}`}
                                className={`panelHeaderIconDiv displayIcon ${
                                    activeControl === name
                                        ? 'active'
                                        : 'inactive'
                                }`}
                                data-tip={`${tooltip}`}
                                data-for={`${name}`}
                            >
                                <IconContext.Provider
                                    value={{
                                        className: `panelHeaderIcon ${
                                            activeControl === name
                                                ? 'active'
                                                : 'inactive'
                                        }`,
                                    }}
                                >
                                    <Icon
                                        onClick={() => {
                                            if (activeControl === name) {
                                                clearControls()
                                            } else {
                                                activateControl(name)
                                            }
                                        }}
                                    />
                                </IconContext.Provider>
                                <Tooltip id={name} />
                            </div>
                        )
                    })}
                </div>
                <div
                    className={`panelHeaderIconDiv displayIcon ${
                        displayTooltip ? 'active' : 'inactive'
                    }`}
                    data-for={'displayTooltip'}
                    data-tip={'Display / Hide Tooltip'}
                >
                    <IconContext.Provider
                        value={{
                            className: `panelHeaderIcon ${
                                displayTooltip ? 'active' : 'inactive'
                            }`,
                        }}
                    >
                        <FaInfoCircle
                            onClick={() => {
                                toggleTooltip(!displayTooltip)
                            }}
                        />
                    </IconContext.Provider>
                    <Tooltip id={'displayTooltip'} />
                </div>
                <div
                    className="panelHeaderIconDiv"
                    data-for={'clearSelection'}
                    data-tip={'Clear Selection'}
                >
                    <IconContext.Provider
                        value={{
                            className: 'mapIcon inactive',
                        }}
                    >
                        <FaTimesCircle
                            onClick={() => {
                                clearControls()
                                clearSelectionLayer()
                            }}
                        />
                    </IconContext.Provider>
                    <Tooltip id={'clearSelection'} />
                </div>
                <div
                    className="panelHeaderIconDiv"
                    data-for={'displayLayers'}
                    data-tip={'Display / Hide Layers'}
                >
                    <IconContext.Provider
                        value={{ className: 'panelHeaderIcon' }}
                    >
                        <FaLayerGroup
                            onClick={() => {
                                toggleMapLayers(!displayMapLayers)
                            }}
                        />
                    </IconContext.Provider>
                    <Tooltip id={'displayLayers'} />
                </div>
                <div
                    className={`panelHeaderIconDiv displayIcon`}
                    data-for={'displayCollapse'}
                    data-tip={ `Collapse / Expand` }
                >
                    <IconContext.Provider
                        value={{
                            className: `panelHeaderIcon ${
                                isCollapsed ? 'active' : 'inactive'
                            }`,
                        }}
                    >
                        { isCollapsed ? 
                            <IoIosArrowUp onClick={ () => toggleCollapsed() } /> 
                                : 
                            <IoIosArrowDown onClick={ () => toggleCollapsed() }/> 
                        }
                    </IconContext.Provider>
                    <Tooltip id={'displayCollapse'} />
                </div>
            </div>
        </div>
    )
}

export default withConfig(MapHeaderControls)