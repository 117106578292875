import React, { useContext, useEffect } from 'react'

import { DataContext } from '../../../explorer/map/DataContext'
import SubmissionsMapHeader from '../submissionsMap/SubmissionsMapHeader'
import ExplorerMap from '../../../explorer/map/ExplorerMap'
import { DataContext as SubmissionsDataContext } from '../../../submissions/DataContext'
import { AppStateContext } from '../../../explorer/AppStateContext'
import MapLayerControls from '../../../explorer/map/layers/MapLayerControls'

export default () => {
    const { getFeatureWithLatLong, latLongFeature, setIsSubmissionsMap } = useContext(DataContext)
    const { mapState: { allFeatures, } } = useContext(AppStateContext)
    const { submissionState, activePanel, } = useContext(SubmissionsDataContext)

    useEffect(() => setIsSubmissionsMap(true), [])

    useEffect(() => {
        if (submissionState && submissionState.facility
            && allFeatures && allFeatures.length
            && activePanel === 'View in Map'
        ) {
            var lat = submissionState.facility.Latitude  
            var long =  submissionState.facility.Longitude  
            if (lat && long) {
                getFeatureWithLatLong(submissionState.facility.Latitude, submissionState.facility.Longitude)
            }
        }
    }, [submissionState, allFeatures, activePanel])

    return (
        <div className='mt-5'>
            <div className={`${activePanel === 'View in Map' && latLongFeature ? '' : 'is-hidden'} `}>
                <SubmissionsMapHeader />
                <MapLayerControls mapMenu={`SubmissionsMap`}/>
                <ExplorerMap mapMenu={activePanel}/>
            </div>
            {activePanel === 'View in Map' && !latLongFeature && !(submissionState.facility.Latitude && submissionState.facility.Longitude) ? (
                <div class="is-size-4 has-text-centered matchingFacilityText">
                    <span class="has-text-weight-semibold">
                        Enter valid Latitude and Longitude coordinates in the Well Location Information tab to plot the new well
                    </span>
                </div>
            ) : null}
        </div>
    )
}