import React, { useContext } from 'react'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import { FaDownload } from 'react-icons/fa'

import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import withConfig from '../../../wrappers/withConfig'
import toast from '../../../elem/Toast'

export default withConfig(({ config, templateType }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { API_URL, EDD_TEMPLATE_TITLE, SAMPLERESULT_EDD_TEMPLATE_TITLE } = config

    const getTitle = () => {
        switch (templateType) {
            case 'Well':
                return EDD_TEMPLATE_TITLE
            case 'SampleResult':
                return SAMPLERESULT_EDD_TEMPLATE_TITLE
            default:
                return EDD_TEMPLATE_TITLE
        }
    }

    const downloadTemplate = () => {
        authenticatedFetch(`${API_URL}/upload/downloadTemplate?templateType=${templateType}`)
            .then(async response => {
                if (response.ok) {
                    return response.blob()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(data => {
                const dateString = dayjs(Date.now()).format('MM-DD-YYYY')
                saveAs(data, `${getTitle(templateType)}_${dateString}.xlsx`)
            })
            .catch(e => {
                toast({
                    level: 'error',
                    message:
                        'Download Failed: ' +
                        (e.message
                            ? e.message
                            : 'Contact the system administrator to ensure the file is present on the server.'),
                })
            })
    }
    return (
        <div className="center-content">
            <button type="button" className="button is-medium is-primary" onClick={() => downloadTemplate()}>
                <span className="icon">
                    <FaDownload />
                </span>
                <span>
                    Download EDD Template
                </span>
            </button>
        </div>
    )
})
