import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react'
import Select from 'react-select'
import { useForm } from 'react-hook-form'
import { FaInfoCircle } from 'react-icons/fa'
import merge from 'lodash.merge'

import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import { DataContext } from '../DataContext'
import SelectStyles from '../../../elem/form/SelectStyles'
import withConfig from '../../../wrappers/withConfig'
import Tooltip from '../../../elem/Tooltip'
import {
    aliasHelperText,
    reasonForEmptyDNRDataHelperText,
} from '../../../../utils/submissions/helperText'
import toast from '../../../elem/Toast'
import SubmissionsMap from './submissionsMap/SubmissionsMap'

const Helper = ({ text, dataFor }) => (
    <span
        data-tip={`${text}`}
        data-for={dataFor}
        className="icon is-left is-small formHelper"
    >
        <FaInfoCircle />
        <Tooltip id={dataFor} className={'with-text-wrap'} />
    </span>
)

const ExistingWellComponent = withConfig(({ config, setFacilityData, targetField, setTargetField, setContinueDisabled }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { activeAgency, setFormMethods } = useContext(DataContext)
    const formMethods = useForm({ mode: 'onChange' })
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState('')
    const [valuesChanged, setValuesChanged] = useState(false)
    const [error, setError] = useState(null)
    const { register, handleSubmit } = formMethods
    const searchDisabled = useMemo(() => !value, [value])
    const { API_URL } = config

    useEffect(() => {
        if (value !== "" && !valuesChanged) {
            setContinueDisabled(false)
        } else {
            setContinueDisabled(true)
        }
    }, [valuesChanged, value])

    useEffect(() => {
        setFormMethods(formMethods)
    }, [])

    const search = (v, field) => {
        if (v === '') {
            setError('Please enter a value.')
            setFacilityData({})
            return
        }
        if (field === '' || field === null) {
            setError('Please select an identifier for the existing well.')
            setFacilityData({})
            return
        }
        if (field === "ClearinghouseNumber" || field === "DNRWellID") {
            if (parseFloat(v) !== parseInt(v)) {
                setError('Value should be an integer.')
                setFacilityData({})
                return
            }
            if (parseInt(v) < 1) {
                setError('Value cannot be less than 1.')
                setFacilityData({})
                return
            }
        }
        if (field === "DNRWellID") {
            if (v.length > 9) {
                setError("There is no well that meets that search criteria.")
                setFacilityData({})
                return
            }
        }
        setLoading(true)
        setFacilityData({})
        authenticatedFetch(
            `${API_URL}/upload/facilityInfo?columnName=${field}&columnValue=${v}&agencyCode=${activeAgency}`
        )
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                setFacilityData(response.facilityInfo)
                setError(null)
                setValuesChanged(false)
            })
            .catch(e => {
                setError(e.message)
                setFacilityData({})
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            <form
                className="form"
                onSubmit={handleSubmit(() => search(value, targetField))}
            >
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-label">
                        <label className="label">Identify Well By:</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <Select
                                className="select is-multiple is-fullwidth reactSelect"
                                classNamePrefix="reactSelect"
                                options={[
                                    { value: 'Alias', label: 'Alias' },
                                    {
                                        value: 'ClearinghouseNumber',
                                        label: 'Clearinghouse Number',
                                    },
                                    {
                                        value: 'DNRWellID',
                                        label: 'DNR Well ID',
                                    },
                                ]}
                                name="columnName"
                                // ref={register}
                                styles={SelectStyles}
                                menuPlacement="auto"
                                onChange={data => {
                                    setValue('')
                                    setTargetField(data.value)
                                    setValuesChanged(true)
                                }}
                            />
                        </div>
                        <div className="field">
                            <input
                                className={`input is-fullwidth`}
                                onChange={e => {
                                    setValuesChanged(true)
                                    setValue(e.target.value)
                                }}
                                value={value}
                                ref={register}
                                name="columnValue"
                                type={`${
                                    targetField === 'ClearinghouseNumber' || targetField === 'DNRWellID'
                                        ? 'number'
                                        : null
                                }`}
                            />
                        </div>
                        <div className="field">
                            <button
                                type="submit"
                                disabled={`${searchDisabled ? "true" : ""}`}
                                className={`button is-primary ${loading ? 'is-loading' : ''}`}
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                {error ? <div className="has-text-danger">{error}</div> : null}
            </form>
        </>
    )
})

const NewWellComponent = withConfig(({ config, setFacilityData , setContinueDisabled, DNRValidated, setDNRValidated, inputHasValue, toggleInputHasValue }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { activeAgency, setFormMethods } = useContext(DataContext)
    const [alias, setAlias] = useState(null)
    const [valuesChanged, setValuesChanged] = useState(false)
    const [DNRWellID, setDNRWellID] = useState(null)
    const [loading, setLoading] = useState(false)
    const formMethods = useForm({ mode: 'onChange' })
    const [error, setError] = useState(null)
    const { handleSubmit } = formMethods
    const { API_URL } = config
    const searchDisabled = useMemo(() => (!alias && !DNRWellID), [alias, DNRWellID])
    
    useEffect(() => {
        const { Latitude, Longitude, coordinatesValidated } = inputHasValue
        if (
            ((DNRWellID || (DNRWellID && alias)) && DNRValidated) //dnr well id is provided and have been successfully searched for
            || (Latitude && Longitude && coordinatesValidated)// cannot insert a new facility without confirming it's location ctrl+shift+f -> validateWellWithLatLong 
        ) {
            setContinueDisabled(false)
        } else {
            setContinueDisabled(true)
        }
    }, [valuesChanged, alias, DNRWellID, inputHasValue, DNRValidated])

    useEffect(() => setFormMethods(formMethods), [])

    useEffect(() => setDNRValidated(false), [DNRWellID, alias]) // if alias or dnrwellid change then stuff ain't good no more

    const search = () => {
        if (DNRWellID) {
            if (isNaN(DNRWellID)) {
                setError("DNR Well ID must be a number.")
                return
            }
            if (parseFloat(DNRWellID) !== parseInt(DNRWellID)) {
                setError("DNR Well ID must be a whole number.")
                return
            }
            if (parseInt(DNRWellID) < 1) {
                setError("DNR Well ID cannot be less than 1.");
                return
            }
            if (DNRWellID.length > 9) {
                setError("There is no well that meets that search criteria.")
                return
            }
        }
        setLoading(true)
        setFacilityData({})
        authenticatedFetch(
            `${API_URL}/upload/facilitySearch?alias=${alias}&DNRWellID=${DNRWellID}&agencyCode=${activeAgency}`
        )
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                const aliasObj = {
                    Alias: alias
                }
                const data = merge({facilityAlias: aliasObj}, response.facilityInfo)
                setFacilityData(data)
                setValuesChanged(false)
                setDNRValidated(true)
                setError(null)
                return response
            })
            .then(response => {
                if (response.error) {
                    setDNRValidated(false)
                    setError(response.error)    
                }
            })
            .catch(e => {
                setError(e.message)
                setFacilityData({})
            })
            .finally(() => setLoading(false))
    }

    const validateDuplicateAliasForLatLong = useCallback((after, handleLoading) => {
        handleLoading(true)
        if (alias) {
            authenticatedFetch(
                `${API_URL}/upload/facilitySearch?alias=${alias}&agencyCode=${activeAgency}`
            )
                .then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(response => {
                    if (response.error) {
                        setError(response.error)
                        handleLoading(false)
                    } else {
                        const aliasObj = {
                            Alias: alias
                        }
                        const data = merge({facilityAlias: aliasObj}, response.facilityInfo)
                        setFacilityData(data)
                        setError(null)
                        after()
                    }
                    return response
                })
                .catch(e => {
                    setError(e.message)
                    setFacilityData({})
                })
                // .finally(() => { })
        } else {
            after()
        }
    }, [alias])

    useEffect(() => { //set errors to null if the DNR Well ID field is empty 
        if (!inputHasValue.DNRWellID || !alias) {
            setError(null)
            setFacilityData({})
        }
    }, [inputHasValue.DNRWellID, alias])

    return (
        <>
            <form className="form" onSubmit={handleSubmit(() => search())}>
                <div className="is-size-4 has-text-centered matchingFacilityText">
                    <span className="has-text-weight-semibold">Well Identifiers: </span>
                    <span className="help has-text-grey">
                        <p>
                            Alias and DNR Well ID are optional fields. If the DNR Well ID is entered, it will be used to auto populate certain fields in this form using data from the DNR Registered Wells database. 
                        </p>
                        <p>
                            If Alias and DNR Well ID fields are left blank, the well will be identified by a Clearinghouse Number assigned after this form is accepted.
                        </p>
                        <p>
                            To verify that a non-DNR well has not already been created, enter the new well’s Latitude (NAD83) and Longitude (NAD83) and press Enter to see the location of the well displayed on the map.
                        </p>
                    </span>
                </div>
                <div className="columns is-vcentered">
                    <div className="column is-11">
                        <div className="field is-horizontal">
                            <div className="field-label is-align-items-center">
                                <label className="label">Alias:</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <input
                                            className="input is-fullwidth "
                                            name={'Alias'}
                                            onChange={e => {
                                                setValuesChanged(true)
                                                setAlias(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <span className="help has-text-grey">{aliasHelperText}</span>
                                </div>
                            </div>
                        </div>
                        <div className="field is-horizontal is-align-items-center">
                            <div className="field-label">
                                <label className="label">DNR Well ID:</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <input
                                        className="input is-fullwidth"
                                        name={'DNRWellID'}
                                        disabled={inputHasValue && (inputHasValue.Latitude || inputHasValue.Longitude)}
                                        onChange={e => {
                                            setValuesChanged(true)
                                            setDNRWellID(e.target.value)
                                            toggleInputHasValue('DNRWellID', !!e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-1">
                        <div className="field">
                            <div className="field-body">
                                <button type="submit" disabled={searchDisabled ? true : false} className={`button is-primary ${loading ? 'is-loading' : ''}`}>
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {error && (inputHasValue.DNRWellID || alias) ? <div className="has-text-danger has-text-centered">{error}</div> : null}
            </form>
            <div className="columns is-centered is-12 pl-5 pt-2">
                <SubmissionsMap width={10} submissionData={{ API_URL, inputHasValue, toggleInputHasValue, setValuesChanged, setFacilityData, validateDuplicateAliasForLatLong }} />
            </div>
        </>
    )
})

const ToggleComponent = ({ updateFunction, setFacilityData, setFacilityID, facilityID }) => {  
    return (
        <form className="form">
            <div className="field is-horizontal is-align-items-center">
                <div className="field-label">
                    <label className="label">New Well to Clearinghouse?</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <Select
                            className="select is-multiple is-fullwidth reactSelect"
                            classNamePrefix="reactSelect"
                            options={[
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                            ]}
                            styles={SelectStyles}
                            menuPlacement="auto"
                            onChange={data => {
                                setFacilityData({})
                                setFacilityID(null)
                                updateFunction(data.value)
                            }}
                            defaultValue={[facilityID && facilityID >= 1 ?  
                                            { value: false, label: 'No' } :
                                            { value: true, label: 'Yes' }
                                        ]} //if there is a facilityid then set default value for dropdown to 'No' (indicating this is not a new well)  
                            isDisabled={ facilityID && facilityID >= 1 ? true : false } //disable the dropdown so that the New Well to Clearinghouse? field always displays as “No” when a Draft form is for an existing well. see Jira ticket NW-135
                        />
                    </div>
                </div>
            </div>
        </form>
    )
}

const FacilityInformation = ({ facilityData, duplicateAliasError, continueDisabled, viewOnly, inputHasValue, newWell }) => {
    const [ alias, setAlias ] = useState()

    useEffect(() => {
        setAlias(
            facilityData && facilityData.facilityAlias
            ? facilityData.facilityAlias.Alias
            : ''
        )
    }, [facilityData])

    if (newWell) {
        if ((continueDisabled && !viewOnly) 
            || !inputHasValue.DNRWellID 
            || !(facilityData.facility && Object.keys(facilityData.facility).some(x => facilityData.facility[x] != null))
        ) {
            return null
        }   
    } else {
        if (continueDisabled && !viewOnly)  {
            return null
        }
    }

    return facilityData && facilityData.facility ? (
        <div className="column is-12">
            <div className="columns is-centered">
                <div className="column is-6">
                    <form className="form is-centered">
                        <div className="is-size-4 has-text-weight-semibold has-text-centered matchingFacilityText">
                            Well Information:{' '}
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-label">
                                <label className="label">Alias: </label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <input
                                            className="input is-small"
                                            disabled
                                            value={alias}
                                        />
                                        <p className="help has-text-gray">{aliasHelperText}</p>
                                        {duplicateAliasError ? <p className="help is-danger ">{duplicateAliasError}</p> : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-label">
                                <label className="label">Clearinghouse Number: </label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control is-expanded">
                                        <input
                                            disabled
                                            className="input is-small"
                                            value={
                                                facilityData && facilityData.facility
                                                    ? facilityData.facility
                                                        .ClearinghouseNumber
                                                    : ''
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-label">
                                <label className="label">DNR Registration Number: </label>
                            </div>
                            <div className="field-body">
                                <div className="field has-addons">
                                    <div className="control">
                                        <div className="button is-small is-static border-none">
                                            <Helper
                                                text={reasonForEmptyDNRDataHelperText}
                                                dataFor="emptyDNRRegistration"
                                            />
                                        </div>
                                    </div>
                                    <div className="control is-expanded">
                                        <input
                                            disabled
                                            className="input is-small"
                                            value={
                                                facilityData && facilityData.facility
                                                    ? facilityData.facility
                                                        .DNRRegistrationNumber_Full
                                                    : ''
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-label">
                                <label className="label">DNR Well ID: </label>
                            </div>
                            <div className="field-body">
                                <div className="field has-addons">
                                    <div className="control formHelper">
                                        <span className="button is-small is-static border-none">
                                            <Helper
                                                text={reasonForEmptyDNRDataHelperText}
                                                dataFor="emptyDNRWellID"
                                            />
                                        </span>
                                    </div>
                                    <div className="control is-expanded">
                                        <input
                                            disabled
                                            className="input is-small"
                                            value={
                                                facilityData && facilityData.facility
                                                    ? facilityData.facility.DNRWellID
                                                    : ''
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>            
                        <div className="field is-horizontal">
                            <div className="field-label">
                                <label className="label">Latitude (NAD83): </label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control is-expanded">
                                        <input
                                            disabled
                                            className="input is-small"
                                            value={
                                                facilityData && facilityData.facility
                                                    ? facilityData.facility.Latitude
                                                    : ''
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-label">
                                <label className="label">Longitude (NAD83):</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control is-expanded">
                                        <input
                                            disabled
                                            className="input is-small"
                                            value={
                                                facilityData && facilityData.facility
                                                    ? facilityData.facility.Longitude
                                                    : ''
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    ) : null
}

const DNRWellHelperImage = ({ facilityData }) =>
    facilityData && facilityData.facilityInfo ? null : (
        <div className="dnrHelperImageWrapper">
            <a className="link" target="_blank" rel="noopener noreferrer" href={"https://nednr.nebraska.gov/Dynamic/Wells/Wells"}>https://nednr.nebraska.gov/Dynamic/Wells/Wells</a>
            <img src={`${process.env.PUBLIC_URL}/dnr_well_id_helper.png`}  alt="DNR Well ID can be located at https://nednr.nebraska.gov/dynamic/Wells/Wells under the Well ID field."/>
        </div>
    )

const SaveAndContinueButton = ({ facilityData, duplicateAliasError, continueDisabled }) => {
    const { setSubmissionState, isSubmitting, setTableData } = useContext(DataContext)

    const mergeFacilityData = useCallback(() => {
        if (duplicateAliasError) {
            toast({
                level: 'error',
                message: 'Please fix errors before continuing.',
                alert: true
            })
        } else {
            setSubmissionState(prevSubmissionState => ({
                aquifer: [],
                construction: [],
                facility: {},
                facilityAlias: {},
                waterLevel: [],
                lithology: [],
                result: [],
                sample: [],
                upload: prevSubmissionState.upload,
                new: Math.random() * 1000,
                ...facilityData
            }))
            setTableData({})
        }
    }, [facilityData, duplicateAliasError])

    return (
        <div className="buttonWrapper">
            <button
                type="button"
                onClick={() => mergeFacilityData()}
                disabled={continueDisabled} 
                className={`button is-medium is-link ${isSubmitting ? 'is-loading' : ''}`}
            >
                {`Continue`}
            </button>
        </div>
    )
}

const FacilitySelectionForm = ({ config }) => {
    const [newWell, setNewWell] = useState(true)
    const { activePanel, activeAgency, submissionState, viewOnly, isReview, setFacilityID } = useContext(DataContext)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [ continueDisabled, setContinueDisabled] = useState(false)
    const [targetField, setTargetField] = useState(null)
    const [ facilityData, setFacilityData ] = useState(submissionState)
    const [ duplicateAliasError, setDuplicateAliasError ] = useState(null)
    const [DNRValidated, setDNRValidated] = useState(false)
    const [inputHasValue, setInputHasValue] = useState({ 'DNRWellID': false, 'Latitude': false, 'Longitude': false, 'coordinatesValidated': false })

    const { API_URL } = config

    const updateFacilityAlias = useCallback(alias => {
        // // do not update the facility alias if it has not changed on blur
        // if (facilityData && facilityData && facilityData.facilityAlias) {
        //     if (alias === facilityData.facilityAlias.Alias) {
        //         return
        //     }
        // }
        // otherwise, make sure the alias is allowed and submit
        authenticatedFetch(
            `${API_URL}/upload/facilitySearch?alias=${alias}&DNRWellID=${null}&agencyCode=${activeAgency}`
        )
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                setFacilityData(previousFacilityData => ({
                    ...previousFacilityData,
                    facilityAlias: {
                        ...previousFacilityData.facilityAlias,
                        Alias: alias,
                        IsLegacy: 0
                    }
                }))
                setDuplicateAliasError(null)
            })
            .catch(e => {
                setDuplicateAliasError(e.message)
            })

    }, [facilityData, authenticatedFetch, activeAgency, API_URL])

    const toggleInputHasValue = useCallback((inputName, hasValue) => {
        setInputHasValue(prev => {
            return {
                ...prev,
                [inputName]: hasValue
            }
        })
    }, [inputHasValue]) 

    useEffect(() => {
        setFacilityData(submissionState)
    }, [submissionState])

    if (viewOnly || isReview) {
        return (
        <div className={`column is-12 ${activePanel !== 'Well Search' ? 'is-hidden' : ''}`}>
            <div className="columns is-centered">
                <div className="column is-6">
                    <FacilityInformation
                        viewOnly={viewOnly}
                        continueDisabled={continueDisabled}
                        facilityData={facilityData}
                        updateFacilityAlias={updateFacilityAlias}
                        duplicateAliasError={duplicateAliasError}
                        targetField={targetField}
                    />
                </div>
            </div>
        </div>
        )
    }
    return (
        <div
            className={`columns is-multiline ${
                activePanel !== 'Well Search' ? 'is-hidden' : ''
            }`}
        >
            <div className="column is-12">
                <ToggleComponent updateFunction={d => setNewWell(d)} setFacilityData={setFacilityData} setFacilityID={setFacilityID} 
                    facilityID={facilityData && facilityData.facility ? facilityData.facility.FacilityID : 0} />
            </div>
            <div className="column is-12">
                {newWell ? (
                    <NewWellComponent setFacilityData={setFacilityData} setContinueDisabled={setContinueDisabled} DNRValidated={DNRValidated} setDNRValidated={setDNRValidated} inputHasValue={inputHasValue} toggleInputHasValue={toggleInputHasValue} />
                ) : (
                    <ExistingWellComponent setFacilityData={setFacilityData} targetField={targetField} setTargetField={setTargetField} setContinueDisabled={setContinueDisabled} />
                )}
            </div>
            <FacilityInformation
                continueDisabled={continueDisabled}
                facilityData={facilityData}
                updateFacilityAlias={updateFacilityAlias}
                duplicateAliasError={duplicateAliasError}
                targetField={targetField}
                inputHasValue={inputHasValue}
                newWell={newWell}
            />
            <div className="column is-12">
                <SaveAndContinueButton facilityData={facilityData} duplicateAliasError={duplicateAliasError} newWell={newWell} continueDisabled={continueDisabled} />
            </div>
            <div className="column is-12">
                <DNRWellHelperImage facilityData={facilityData} />
            </div>
        </div>
    )
}

export default withConfig(FacilitySelectionForm)
