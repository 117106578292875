import React, { useContext } from 'react'

import { formatValue } from '../../../../utils/table/parseColumns'
import { DataContext } from './DataContext'

const NitrateOverviewDetails = () => {
    const { detailData, filterData } = useContext(DataContext)
    if (
        !(
            detailData &&
            detailData.nitrateOverview &&
            filterData &&
            filterData.nitrateOverview
        )
    ) {
        return null
    }
    const formFields = filterData.nitrateOverview
    const nitrateDetailsData = detailData.nitrateOverview
    const fieldsToDisplay = Object.keys(nitrateDetailsData).filter(
        x =>
            !!formFields.find(
                field => field.DisplayInTable && field.ColumnName === x
            )
    )
    const displayFields = formFields.filter(x =>
        fieldsToDisplay.includes(x.ColumnName)
    )
    return (
        <div className="columns is-multiline is-variable is-1 detailContentWrapper">
            <div className="column is-full detailsDataWrapper">
                <div className="detailsDataHeader">Nitrate Overview</div>
                <div className="detailsData nitrateDetailsData">
                    {displayFields.map((field, idx) => {
                        const fieldLabel = field.Prompt
                        const fieldColumn = field.ColumnName
                        const fieldValue = nitrateDetailsData[fieldColumn]
                        return (
                            <div
                                key={`field-${idx}`}
                                className="field is-horizontal nitrateDetailsField"
                            >
                                <div className="label is-size-7">
                                    {fieldLabel}
                                </div>
                                <div
                                    className="value margin-left-sm is-size-7"
                                    dangerouslySetInnerHTML={{
                                        __html: formatValue(fieldValue, true),
                                    }}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default NitrateOverviewDetails
