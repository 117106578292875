import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ReactSelect from 'react-select'

import { UserContext } from '../../wrappers/UserContext'
import { checkRole, getAgencyCode } from '../../../utils/user/permissions'
import { DataContext } from './DataContext'
import LoadingWrapper from '../../wrappers/LoadingWrapper'
import urls from '../../../utils/constants/urls'
import Breadcrumbs from './form/Breadcrumbs'

const AgencyMessage = ({ agency }) => (
    <div className="column is-7">Submitting results as {agency.AgencyName}</div>
)

const FormReviewerSelection = ({ agencies, setAgencyCode }) => {
    const options = agencies.map(agency => ({
        label: agency.AgencyName,
        value: agency.AgencyCode,
    }))

    return (
        <div className="column is-7">
            Please select an agency to submit sample results for:
            <div className="">
                <ReactSelect
                    className="select is-multiple is-small is-multiple is-fullwidth reactSelect"
                    classNamePrefix="reactSelect"
                    options={options}
                    menuPlacement="auto"
                    onChange={option => setAgencyCode(option.value)}
                />
            </div>
        </div>
    )
}

const ContinueButton = ({ onClick }) => (
    <div className="button is-link is-medium" onClick={onClick}>
        Continue
    </div>
)

const CancelButton = () => (
    <Link to={urls.uploadDialogue}>
        <div className="button is-medium">Back</div>
    </Link>
)

export default ({ onSubmit }) => {
    const { roles } = useContext(UserContext)
    const { agencies } = useContext(DataContext)
    const [agencyCode, setAgencyCode] = useState(getAgencyCode(roles))
    const [submit, setSubmit] = useState(false)
    const [isFormReviewer, setIsFormReviewer] = useState(
        checkRole('Form_Reviewer', roles)
    )
    const [selectedAgency, setSelectedAgency] = useState()

    useEffect(() => {
        setAgencyCode(getAgencyCode(roles))
        setIsFormReviewer(checkRole('Form_Reviewer', roles))
    }, [roles])

    useEffect(() => {
        if (agencies && agencyCode) {
            setSelectedAgency(
                agencies.find(agency => agency.AgencyCode === agencyCode)
            )
        }
    }, [agencyCode, agencies])

    useEffect(() => {
        if (submit) {
            onSubmit(agencyCode)
        }
    }, [submit, agencyCode])

    return (
        <div className="hero is-fullheight-with-navbar">
            <Breadcrumbs />
            <div className="hero-body">
                <div className="container">
                    <div className="section">
                        <div className="columns is-centered is-multiline">
                            <LoadingWrapper data={agencies}>
                                {isFormReviewer && agencies ? (
                                    <FormReviewerSelection
                                        agencies={agencies}
                                        setAgencyCode={setAgencyCode}
                                    />
                                ) : null}
                                {!isFormReviewer && selectedAgency ? (
                                    <AgencyMessage agency={selectedAgency} />
                                ) : null}
                                <div className="column is-7 is-centered buttonWrapper">
                                    {selectedAgency ? (
                                        <ContinueButton
                                            onClick={() => setSubmit(true)}
                                        />
                                    ) : null}
                                    <CancelButton />
                                </div>
                            </LoadingWrapper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
