import React, { useContext } from 'react'

import { UpArrow, DownArrow } from '../Arrows'
import { ParameterContext } from '../../wrappers/ParameterContext'
import { hasDateParams } from '../../../utils/chart/values'

export default ({
    toggleTimeWindowDropdown,
    displayTimeWindowDropdown,
    timeWindow,
    inHeader=true,
    explorer='sample',
    fieldName='SimpleSampleDate'
}) => {
   const { params } = useContext(ParameterContext)

   if (hasDateParams(params, explorer, fieldName)) {
    return null
}
    // hide this dropdown if there is a date window applied
    const dateValue = params.sample && params.sample.SimpleSampleDateValue ? params.sample.SimpleSampleDateValue : null
    if (dateValue) {
        return null
    }
    return (
        <div
            className="panelButton"
            onClick={() => toggleTimeWindowDropdown(!displayTimeWindowDropdown)}
        >
            <span className="panelTimeOptionText">{timeWindow.name}</span>
            <span className="panelTimeOptionDropdown">
                {displayTimeWindowDropdown ? (
                    <UpArrow color={'white'} className={'is-white'} />
                ) : (
                    <DownArrow color={'white'} className={'is-white'} />
                )}
            </span>
        </div>
    )
}
