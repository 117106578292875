import React, { useContext } from 'react'

import { DataContext } from './DataContext'
import NoDataComponent from '../../../elem/table/client/NoDataComponent'
import TableContent from '../../../elem/table/client/TableContent'
import parseColumns from '../../../../utils/table/parseColumns'

export default () => {
    const { detailData, filterData, visibleTab, isLoading } = useContext(DataContext)
    if (
            detailData &&
            detailData.screen &&
            filterData &&
            filterData.screen &&
            visibleTab === 'screens'
    ) {
        const formFields = filterData.screen
        const screenDetailsData = detailData.screen
        const columns = parseColumns(screenDetailsData, formFields)
        return (
            <div className="is-size-7 detailTableWrapper screenDetailWrapper">
                <NoDataComponent data={screenDetailsData} isLoading={isLoading} />
                <TableContent
                    name="screenDetails"
                    columns={columns}
                    data={screenDetailsData}
                    loading={isLoading}
                    initialSort={'ScreenFromDepth'}
                    initialSortDesc={false}
                />
            </div>
        )

    } else {
        return null
    }
}