import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style'

const activeFill = new Fill({
    color: 'rgb(50, 115, 220)',
})

const activeStroke = new Stroke({
    color: '#ffffff',
    width: 1,
})

const inactiveFill = new Fill({
    color: '#9C9C9C',
})

// const inactiveStroke = new Stroke({
//     color: 'rgb(0, 100, 255)',
//     width: 1,
// })

const activeStyle = { stroke: activeStroke, fill: activeFill }
const inactiveStyle = { fill: inactiveFill }
const styles = {
    1: {
        1: new Style({
            image: new CircleStyle({
                radius: 3,
                ...activeStyle,
            }),
            zIndex: 10
        }),
        2: new Style({
            image: new CircleStyle({
                radius: 4,
                ...activeStyle,
            }),
            zIndex: 10
        }),
        3: new Style({
            image: new CircleStyle({
                radius: 5,
                ...activeStyle,
            }),
            zIndex: 10
        }),
        4: new Style({
            image: new CircleStyle({
                radius: 6,
                ...activeStyle,
            }),
            zIndex: 10
        }),
        5: new Style({
            image: new CircleStyle({
                radius: 7,
                ...activeStyle,
            }),
            zIndex: 10
        }),
        6: new Style({
            image: new CircleStyle({
                radius: 8,
                ...activeStyle,
            }),
            zIndex: 10
        }),
        7: new Style({
            image: new CircleStyle({
                radius: 9,
                ...activeStyle,
            }),
            zIndex: 10
        }),
    },
    0: {
        1: new Style({
            image: new CircleStyle({
                radius: 1,
                ...inactiveStyle,
            }),
        }),
        2: new Style({
            image: new CircleStyle({
                radius: 2,
                ...inactiveStyle,
            }),
        }),
        3: new Style({
            image: new CircleStyle({
                radius: 3,
                ...inactiveStyle,
            }),
        }),
        4: new Style({
            image: new CircleStyle({
                radius: 4,
                ...inactiveStyle,
            }),
        }),
        5: new Style({
            image: new CircleStyle({
                radius: 5,
                ...inactiveStyle,
            }),
        }),
        6: new Style({
            image: new CircleStyle({
                radius: 6,
                ...inactiveStyle,
            }),
        }),
        7: new Style({
            image: new CircleStyle({
                radius: 7,
                ...inactiveStyle,
            }),
        }),
    },
    invisible: new Style({
        image: new CircleStyle({
            stroke: null,
            fill: null,
        }),
    }),
    highlighted: new Style({
        image: new CircleStyle({
            stroke: new Stroke({ color: 'rgb(100, 80, 210)', width: 2 }),
            fill: new Fill({ color: 'rgb(210, 80, 210)' }),
            radius: 5,
        }),
        zIndex: 12,
    }),
}

const drawStyles = {
    polygonStyles: new Style({
        stroke: new Stroke({color: 'rgb(235, 162, 80)', width: 2}),
        fill: new Fill({color: 'rgba(235, 162, 80, 0.3)'}),
    }),
    pointerStylesHighlighted: new Style ({
        image: new CircleStyle({
            ...activeStyle,
            radius: 7,
        })
    }),
    pointerStylesSelected: new Style ({
        image: new CircleStyle({
            ...inactiveStyle,
            radius: 7,
        })
    }),
    boundingBoxStyles: new Style({
        stroke: new Stroke({color: 'rgb(235, 162, 80)'}),
    }),
}

const wellColors = ['#006100', '#559100', '#E6E600', '#FFAA00', '#FF5500', '#A80084', '#4C0073', '#9C9C9C']
const sizes = [1,2,3,4,5,6,7]
const wellStyles = wellColors.reduce((acc, curr) => {
    const color = curr
    const options = sizes.reduce((sizeOptions, size) => {
        return {
            ...sizeOptions,
            [size]: new Style({
                image: new CircleStyle({
                    fill: new Fill({
                        color
                    }),
                    radius: size
                })
            })
        }
    }, {})
    return {
        ...acc,
        [color]: options
    }
}, {})

const unthemedStyles = sizes.reduce((acc, curr) => {
    return {
        ...acc,
        [curr]: new Style({
            image: new CircleStyle({
                fill: new Fill({
                    color: 'grey'
                }),
                radius: curr
            })
        })
    }
}, {})

const redDotFeatureStyle = sizes.reduce((acc, curr) => {
    return {
        ...acc,
        [curr]: new Style({
            image: new CircleStyle({
                fill: new Fill({
                    color: 'red'
                }),
                radius: curr
            }),
            zIndex: 120
        })
    }
}, {})

const styleFunction = function(themed, feature, zoomLevel) {
    const selected = feature.get('selected')
    const displayed = feature.get('displayed')
    const highlighted = feature.get('highlighted')
    const redDot = feature.get('redDot')

    if (displayed) {
        const size = Math.ceil(zoomLevel)
        if (redDot) {
            return redDotFeatureStyle[size]
        }
        if (highlighted) {
            return styles['highlighted']
        }
        if (selected) {
            return styles[selected][size]
        } else {
            if (themed) {
                try {
                    return wellStyles[feature.get('MostRecentNitrateConcentration')][size]
                } catch(e) {
                    console.log('e:', e)
                    console.log(wellStyles)
                    console.log('size:', size)
                    console.log('color:', feature.get('MostRecentNitrateConcentration'))
                }
            } else {
                return unthemedStyles[size]
            }
        }
    }
    return styles.invisible[0]
}

export { drawStyles }
export default styleFunction
