import React from 'react'

import { FaArrowAltCircleUp, FaRegArrowAltCircleUp, FaSlidersH, FaThList } from 'react-icons/fa'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'

import PanelHeader from '../../../elem/panel/PanelHeader'
import { IconTooltip as Tooltip } from '../../../elem/Tooltip'

export default ({ 
    filterExpanded,
    toggleFilter,
    togglePromotedRecords,
    promotedRecords,
    setCollapsed,
    isCollapsed,
 }) => {
    return (
        <PanelHeader extraClass={'samplePanelHeader'}>
            <div className="panelHeaderText">Sample Result Explorer</div>
            <div className="panelHeaderControls">
                <div className="panelHeaderIconWrapper">
                    {!filterExpanded && (
                        <div
                            className="panelHeaderIconDiv"
                            data-tip={`${
                                promotedRecords
                                    ? `Do not promote Selected Records`
                                    : `Promote Selected Records`
                            }`}
                            data-for={`well-promoted-records-toggle`}
                            onClick={() =>
                                togglePromotedRecords(!promotedRecords, 'sample')
                            }
                        >
                            {promotedRecords ? (
                                <FaArrowAltCircleUp />
                            ) : (
                                <FaRegArrowAltCircleUp />
                            )}
                            <Tooltip
                                id={`well-promoted-records-toggle`}
                            />
                        </div>
                    )}
                    <div
                        className="panelHeaderIconDiv"
                        data-tip={`${
                            filterExpanded
                                ? 'View Sample Result List'
                                : 'View Sample Result Filter'
                        }`}
                        data-for={`sample-view-toggle`}
                        onClick={() => toggleFilter(!filterExpanded)}
                    >
                        {filterExpanded ? <FaThList /> : <FaSlidersH />}
                        <Tooltip id={'sample-view-toggle'} />
                    </div>
                    <div
                        className="panelHeaderIconDiv"
                        data-tip={`Collapse / Expand`}
                        data-for={`sample-panel-toggle`}
                        onClick={() => setCollapsed(!isCollapsed)}
                    >
                        {isCollapsed ? <IoIosArrowUp /> : <IoIosArrowDown />}
                        <Tooltip
                            id={`sample-panel-toggle`}
                        />
                    </div>
                    <Tooltip
                        id={'sample-export-button'}
                        className={'is-white'}
                    />
                </div>
            </div>
        </PanelHeader>
    )
}
