import React, { useContext} from 'react'

import { DataContext } from './DataContext'
import Modal from '../../elem/Modal'

export default () => {
    const { displayUnsavedChangesModal, discardUnsavedChanges, setDisplayUnsavedChangesModal } = useContext(DataContext)

    return (
        <Modal
            display={displayUnsavedChangesModal}
            confirmAction={() => discardUnsavedChanges()}
            confirmText={`Discard Changes`}
            denyAction={() => setDisplayUnsavedChangesModal(false)}
            denyText={`Cancel`}
            title={'Unsaved Changes'}
        >
            There are unsaved changes. Do you wish to discard these changes and continue?
        </Modal>
    )
}
