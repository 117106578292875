import React, { useContext } from 'react'

import { formatValue } from '../../../../utils/table/parseColumns'
import { DataContext } from './DataContext'

const LastSampleDetails = () => {
    const { detailData, filterData } = useContext(DataContext)
    if (
        !(
            detailData &&
            detailData.lastSample &&
            filterData &&
            filterData.lastSample
        )
    ) {
        return null
    }
    const formFields = filterData.lastSample
    const lastSampleDetailsData = detailData.lastSample
    const fieldsToDisplay = Object.keys(lastSampleDetailsData).filter(
        x =>
            // filter out those fields that are not supposed to be displayed
            // in the table
            !!formFields.find(
                field => field.DisplayInTable && field.ColumnName === x
            )
    )

    const displayFields = formFields.filter(x =>
        fieldsToDisplay.includes(x.ColumnName)
    )
    return (
        <div className="columns is-multiline is-variable is-1 detailContentWrapper">
            <div className="detailsData nitrateDetailsData">
                {displayFields.map((field, idx) => {
                    const fieldLabel = field.Prompt
                    const fieldColumn = field.ColumnName
                    const fieldValue = lastSampleDetailsData[fieldColumn] ? lastSampleDetailsData[fieldColumn] : 'None'
                    return (
                        <div className="column is-full detailsDataWrapper" key={idx}>
                            <div className="detailsDataHeader">
                                {fieldLabel}
                                <span
                                    className="has-text-weight-normal"
                                    dangerouslySetInnerHTML={{
                                        __html: formatValue(fieldValue),
                                    }}
                                />
                            </div>
                        </div>
                       
                    )
                })}
            </div>
        </div>
    )
}

export default LastSampleDetails
