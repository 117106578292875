import React, { useState, createContext, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import deepEqual from 'deep-equal'

import { queryStringParser, removeOrderAndPageParams } from '../../utils/params'
import { defaultParams } from '../../utils/constants/params'
import { mapUrlToName } from '../../utils/constants/urls'

const ParameterContext = createContext(null)

const ParameterContextProvider = ({ children }) => {
    const location = useLocation()
    const defaults = defaultParams[mapUrlToName[location.pathname]]
    const queryString = location.search
    
    // parse the url params. the names will be like well<filtername>, sample<filterName>
    const urlParamsArray = queryStringParser(queryString)

    // take those params and turn into the format
    // urlParams = {well: {<filtername>}, sample: {<filtername>}}
    const urlParams = defaults ? Object.keys(defaults).reduce((acc, currentParamGroup) => {
        const relatedParams = Object.keys(urlParamsArray).filter(x => x.startsWith(currentParamGroup)).reduce((acc, currentParameter) => {
            const parsedParamName = currentParameter.split(currentParamGroup)[1]
            const value = urlParamsArray[currentParameter]
            return {
                ...acc,
                [parsedParamName]: value
            }
        }, {})
        return {
            ...acc,
            [currentParamGroup]: {
                ...defaults[currentParamGroup],
                ...relatedParams
            }
        }
    }, {}) : {}

    // set the initial params as the combination
    // of defaults and those inherited from url
    // parameters
    const [params, setParams] = useState({
        ...defaults,
        ...urlParams,
    })

    // given a target, set the params[target] =
    // newParams
    const updateParams = useCallback((newParams, target) => {
        const otherTargets = Object.keys(params).filter(formName => formName !== target)
        const newParamsWOrdersRemoved = removeOrderAndPageParams({...params, [target]: newParams}, target)
        const oldParamsWOrdersRemoved = removeOrderAndPageParams(params, target)
        const shouldUpdatePages = !deepEqual(newParamsWOrdersRemoved, oldParamsWOrdersRemoved)
        let updatedParams = {...params, [target]: newParams}
        if (shouldUpdatePages) {
            const paramsWithUpdatedPages = otherTargets.reduce((acc, formName) => {
                return {
                    ...acc,
                    [formName]: {
                        ...params[formName],
                        Page: 1
                    }
                }
            }, {})
            updatedParams = {...paramsWithUpdatedPages, [target]: newParams}
        }
        setParams(updatedParams)
    }, [params])

    return (
        <ParameterContext.Provider
            value={{
                params,
                setParams: updateParams,
            }}
        >
            {children}
        </ParameterContext.Provider>
    )
}

export { ParameterContext }
export default ParameterContextProvider