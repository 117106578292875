import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { FaChevronRight } from 'react-icons/fa'

import Breadcrumbs from './form/Breadcrumbs'
import { SimpleSpinner } from '../../elem/Spinner'
import { DataContext } from './DataContext'

export default () => {
    const { formTypes } = useContext(DataContext)
    if (!formTypes) {
        return <SimpleSpinner />
    }    
    return (
    <div className="hero is-fullheight-with-navbar">
        <Breadcrumbs />
        <div className="hero-body">
            <div className="container">
                {formTypes.map((formType, idx) => (
                    <div className="section">
                        <div className="columns is-centered is-vcentered">
                            <div className="column is-6">
                                <article className={`message is-${idx}`}>
                                    <div className="message-header space-between">
                                        <Link
                                            to={formType.NewFormURL}
                                            className="no-underline full-width"
                                        >
                                            <div className="headerWrapper space-between">
                                                <span className="headerText display-flex center-vertically">
                                                    {formType.NewFormName}
                                                </span>
                                                <span className="iconWrapper icon is-medium">
                                                    <FaChevronRight />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="message-body" dangerouslySetInnerHTML={{__html: formType.NewFormDescription}}>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
)}