import React from 'react'

export default ({ title, subtitle, children }) => (
    <div className="formSectionWrapper">
        <div className="subtitle">{title}</div>
        {subtitle ? (<p>
            {subtitle.split('$').map(x => <i key={x}>{x}<br/></i>)}
        </p>) : null}
        {children}
    </div>
)