import React, { useState, useContext, useCallback, useMemo, useEffect } from "react"
import { IconContext } from "react-icons"
import { FaCheck, FaInfoCircle } from "react-icons/fa"
import { APIRequestContext } from "../../../../wrappers/APIRequestContext"

import { DataContext } from '../../../explorer/map/DataContext'
import ExplorerMap from "../../../explorer/map/ExplorerMap"
import SubmissionsMapHeader from "./SubmissionsMapHeader"
import { Tooltip } from "../../../../elem/Tooltip"
import MapLayerControls from "../../../explorer/map/layers/MapLayerControls"

export default ({ submissionData }) => {
    const { API_URL, toggleInputHasValue, inputHasValue, setFacilityData, validateDuplicateAliasForLatLong } = submissionData

    const { authenticatedFetch } = useContext(APIRequestContext)
    const [latitude, setLatitude] = useState()
    const [longitude, setLongitude] = useState()
    const [errors, setErrors] = useState([])
    const [enterLoading, setEnterLoading] = useState(false)
    const [message, setMessage] = useState()
    const enterDisabled = useMemo(() => (!latitude || !longitude), [latitude, longitude])
    const { getFeatureWithLatLong, latLongFeature, setIsSubmissionsMap, setLoading } = useContext(DataContext)
    const latValidValues = { min: 40, max: 43.0002 }
    const longValidValues = { min: -104, max: -95.33 }
    
    useEffect(() => setIsSubmissionsMap(true), [])

    const validateWellWithLatLong = (lat, long) => {
        toggleInputHasValue('coordinatesValidated', false)
        setMessage(null)
        setErrors([])
        var e = checkErrorsAndValidate()
        if (e.length > 0) {
            return
        }
        authenticatedFetch(
            `${API_URL}/upload/facilitySearchWithLatLong?latitude=${lat}&longitude=${long}`
        )
        .then(async response => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then(response => {
            if (response && response.error) {
                setMessage(response.error)
                return
            }
            getFeatureWithLatLong(latitude, longitude)
            setFacilityData(previousFacilityData => ({
                ...previousFacilityData,
                facility: {
                    ...previousFacilityData.facility,
                    Latitude: Number(latitude),
                    Longitude: Number(longitude),
                    ...response.facilityInfo.stampData,
                    IsLegacy: 0
                }
            }))
            toggleInputHasValue('coordinatesValidated', true)
        })
        .catch(e => {
            toggleInputHasValue('coordinatesValidated', false)
            console.log(e)
        })
        .finally(() => {
            setEnterLoading(false)
            setLoading(false)
        })
    }

    const checkMinMaxErrors = (value, valueName, min, max) => {
        if (value < min) {
            return `Value cannot be less than ${min} `            
        } 
        if (value > max) {
            return `Value cannot be greater than ${max}`
        } 
        return null
    }
    
    const checkErrorsAndValidate = useCallback(() =>  {
        let errorList = []
        const latitudeMinMaxError = checkMinMaxErrors(latitude, 'Latitude', latValidValues.min, latValidValues.max)
        const longitudeMinMaxError = checkMinMaxErrors(longitude, 'Longitude', longValidValues.min, longValidValues.max)
        
        if (latitudeMinMaxError) errorList.push(latitudeMinMaxError)
        if (longitudeMinMaxError) errorList.push(longitudeMinMaxError)
        
        setErrors(errorList)
        return errorList
    }, [latitude, longitude])
    
    const latCurrentError = useMemo(() => checkMinMaxErrors(latitude, 'Latitude', latValidValues.min, latValidValues.max), [latitude])
    const longCurrentError = useMemo(() => checkMinMaxErrors(longitude, 'Longitude', longValidValues.min, longValidValues.max), [longitude])

    //if lat or long change then set coordinates validated to false
    useEffect(() => {
        toggleInputHasValue(`coordinatesValidated`, false)
        if (!latitude || !longitude) {
            setMessage(null)            
        }
    }, [latitude, longitude])

    return (
        <>
            <div className={`${latLongFeature && errors.length <= 0 && !message && inputHasValue.coordinatesValidated ? `` : `is-hidden`}`}>
                <SubmissionsMapHeader />
                <MapLayerControls mapMenu={`SubmissionsMap`}/>
                <ExplorerMap mapMenu={'GetLatLongMenu'}/>
            </div>
            <div className='columns is-vcentered is-justify-content-space-evenly mt-2'>
                <div className="column is-5">
                    <div className="field is-horizontal is-align-items-center">
                        <div className="field-label">
                            <label className="label">{`Latitude (NAD83):`}</label>
                        </div>
                        <div className="field-body">
                            <div className="field is-expanded">
                                <div className="field has-addons">
                                    <div className="control is-expanded has-icons-left">
                                        <input
                                            className="input is-small formInput"
                                            name={'Latitude'}
                                            type="number"
                                            min={latValidValues.min}
                                            max={latValidValues.max}
                                            disabled={ inputHasValue && inputHasValue.DNRWellID }
                                            onChange={ e => {
                                                const value = e.target.value
                                                setLatitude(value)
                                                toggleInputHasValue('Latitude', !!value)
                                            }}
                                        />
                                        <span
                                            data-tip={`Enter between 40 and 43.0002`}
                                            data-for={`Latitude-helper`}
                                            className="icon is-left formHelper"
                                        >
                                            <FaInfoCircle />
                                            <Tooltip
                                                id={`Latitude-helper`}
                                                className={''}
                                            />
                                        </span>                    
                                        {latCurrentError && inputHasValue.Latitude && (
                                            <div className="help is-danger inputErrors">
                                                <span className="inputErrorText">{latCurrentError}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>                            
                            </div>                            
                        </div>
                    </div>
                </div>
                <div className="column is-5">
                    <div className="field is-horizontal is-align-items-center">
                        <div className="field-label">
                            <label className="label">{`Longitude (NAD83):`}</label>
                        </div>
                        <div className="field-body">
                            <div className="field is-expanded">
                                <div className="field has-addons">
                                    <div className="control is-expanded has-icons-left">
                                        <input
                                            className="input is-small formInput"
                                            name={'Longitude'}
                                            type="number"
                                            min={longValidValues.min}
                                            max={longValidValues.max}
                                            disabled={ inputHasValue && inputHasValue.DNRWellID }
                                            onChange={e => {
                                                const value = e.target.value
                                                setLongitude(value)
                                                toggleInputHasValue('Longitude', !!value)
                                            }}
                                        />
                                        <span
                                            data-tip={`Enter between -104 and -95.33`}
                                            data-for={`Longitude-helper`}
                                            className="icon is-left formHelper"
                                        >
                                            <FaInfoCircle />
                                            <Tooltip
                                                id={`Longitude-helper`}
                                                className={''}
                                            />
                                        </span>
                                        {longCurrentError && inputHasValue.Longitude && (
                                            <div className="help is-danger inputErrors">
                                                <span className="inputErrorText">{longCurrentError}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>                            
                            </div>                            
                        </div>
                    </div>
                </div>
                <div className="column is-1">
                    <div className="field">
                        <div className="field-body">
                            <button type="button" 
                                disabled={enterDisabled}
                                className={`button is-primary ${enterLoading ? 'is-loading' : ''}`}
                                onClick={() => {
                                    setLoading(true)
                                    setEnterLoading(true)
                                    //validate if the Alias already exists then validate lat long
                                    validateDuplicateAliasForLatLong(
                                        () => validateWellWithLatLong(latitude, longitude),
                                        (loadingValue) => {
                                            setLoading(loadingValue)
                                            setEnterLoading(loadingValue)
                                        }
                                    )
                                }}
                            >
                                Enter
                            </button>
                        </div>
                    </div>
                </div>
            </div>
                <div className="is-justify-content-space-evenly">
                    { message ?
                        (  <p className={`has-text-danger-dark has-text-centered`}>
                                {message}
                            </p>
                        ) : null
                    }
                    { inputHasValue.coordinatesValidated && inputHasValue.Latitude && inputHasValue.Longitude
                        ? (
                            <div className="validationIndicatorWrapper">
                                <span className="icon">
                                    <IconContext.Provider value={{ className: 'has-text-success' }}>
                                        <FaCheck />
                                    </IconContext.Provider>
                                </span>
                                <span>Coordinates correctly validated.</span>
                            </div>
                        ) : null
                    }
                    {/* { errors.length > 0
                        ? (
                            <p className={`has-text-danger has-text-left ml-5`}>
                                {errors.join(', ')}
                            </p>
                        )
                        : null
                    } */}
                </div>

        </>
    )
}

