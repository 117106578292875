import React from 'react'
import { dateToString } from '../../../utils/dateUtils'

export default ({ active, payload, label }) => {
    if (active) {
        const formattedLabel = dateToString(label)
        return (
            <div className="customTooltip">
                <p className="customTooltipLabel">{formattedLabel}</p>
                <ul className="customTooltipList">
                    {payload && payload.map((x, idx) =>
                        x.name !== 'NoTooltipItem' ? (
                            <li style={{color: x.color}} key={idx}>
                                <span className="customTooltipItemName">
                                    {x.name}
                                </span>
                                <span> : </span>
                                <span className="customTooltipItemValue">
                                    {Math.round(x.value * 100) / 100}
                                </span>
                                <span className="customTooltipItemUnit">{` ${x.unit}`}</span>
                            </li>
                        ) : null
                    )}
                </ul>
            </div>
        )
    }
    return null
}
