import React, { useContext } from 'react'

import { TableContext } from './TableContext'

const BulkSubmitButton = () => {
    const { selectedUploads, bulkSubmit } = useContext(TableContext)
    return (
        <div className="bulkSubmitWrapper">
            <button className="button is-normal is-link bulkSubmitButton" disabled={selectedUploads.length === 0} onClick={() => bulkSubmit()}>
                Submit
            </button>
        </div>
    )
}

export default BulkSubmitButton