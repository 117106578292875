import React, { useContext } from 'react'

import AgencyConfirmation from '../AgencyConfirmation'
import EDDWellDialogue from './EDDWellDialogue'
import { DataContext } from '../DataContext'

export default () => {
    const { activeAgency, setActiveAgency } = useContext(DataContext)

    return activeAgency ? <EDDWellDialogue /> : <AgencyConfirmation onSubmit={setActiveAgency}/>
}
