import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'

import urls from '../../../../utils/constants/urls'
import Spinner from '../../Spinner'
import { AppStateContext } from '../../../features/explorer/AppStateContext'

const TableBody = ({ getTableBodyProps, page, prepareRow, loading }) => {
    const { mapState: { selectedFeatures, allFeatures, map }, setMapState } = useContext(AppStateContext)
    const selectedClearinghouseNumbers = selectedFeatures.map(feature => feature.get('ClearinghouseNumber'))
    const location = useLocation()
    
    // onClick determines behavior of each row -
    // handles highlight and zoom
    const onClick = (row, isHighlighted) => {
        let newFeatures = selectedFeatures
        if (isHighlighted) {
            // if the row is already highlighted, remove it from the
            // selectedFeatures array on click
            newFeatures = selectedFeatures.filter(x => x.get('ClearinghouseNumber') !== row.original.ClearinghouseNumber)
        } else {
            const newFeature = allFeatures.find(x => x.get('ClearinghouseNumber') === row.original.ClearinghouseNumber)
            if (newFeature) {
                // if the row is not highlighted, add it to the selectedFeatures
                // array on click
                newFeatures = [...selectedFeatures, newFeature]

                // and zoom to fit the map around the selected feature
                const featurePoint = newFeature.getGeometry()
                map.getView().fit(featurePoint, {padding: [170, 50, 30, 150], minResolution: 50})
            } else {
                newFeatures = [...selectedFeatures, {get: () => row.original.ClearinghouseNumber, set: () => {}}]
            }
        }
        setMapState(prevMapState => ({
            ...prevMapState,
            selectedFeatures: newFeatures
        }))
    }

    return (
        <tbody {...getTableBodyProps()}>
            {loading ? (
                <tr>
                    <td colSpan="100%">
                        <Spinner />
                    </td>
                </tr>
            ) : (
                page.map((row, i) => {
                    prepareRow(row)
                    const isHighlighted = selectedClearinghouseNumbers.includes(
                        row.original.ClearinghouseNumber
                    )
                    return (
                        <tr
                            onClick={() => {
                                if (location.pathname === urls.home) {
                                    onClick(row, isHighlighted)
                                }
                            }}
                            {...row.getRowProps({
                                className: isHighlighted ? 'is-selected highlightedRow' : '',
                            })}
                        >
                            {row.cells.map(cell => {
                                return (
                                    <td {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })
            )}
        </tbody>
    )
}

export default TableBody