import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaSave } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import urls from '../../../../utils/constants/urls'
import Breadcrumbs from '../form/Breadcrumbs'
import DownloadTemplate from './DownloadTemplate'
import { EDDDataContext } from './EDDDataContext'
import EDDValidation from './EDDValidation'

const EDDDialogue = () => {
    const { validateFile, uploadFile } = useContext(EDDDataContext)
    const [fileName, setFileName] = useState(null)
    const [valid, setValid] = useState(false)
    const { handleSubmit, register, errors } = useForm({ mode: 'onChange' })
    const history = useHistory()

    const onChange = e => {
        setFileName((e.target.files && e.target.files.length) ? e.target.files[0].name : null)
        setValid(false)
    }

    const onSubmit = data => {
        const file = data.File[0]
        setValid(false)
        if (!valid) {
            validateFile(file).then(validationResults => {
                if (validationResults.errors.length === 0) {
                    setValid(true)
                }
            })
        } else {
            uploadFile(file).then(validationResults => {
                if (validationResults.errors.length === 0) {
                    history.push(urls.manageSampleResults)
                }
            })
        }
    }

    return (
        <div className="hero is-fullheight-with-navbar">
            <Breadcrumbs />
            <div className="hero-body">
                <div className="container">
                    <div className="hero">
                        <h2 className="title has-text-link has-text-centered">
                            Upload Sample Result EDD
                        </h2>
                        <div className="columns is-centered is-multiline">
                            <div className="column is-6">
                                <form
                                    onSubmit={handleSubmit(onSubmit)}
                                    id="uploadForm"
                                    className="form eddForm"
                                >
                                    <div className="file is-medium has-name">
                                        <label className="file-label">
                                            <input
                                                className="file-input"
                                                onChange={onChange}
                                                type="file"
                                                name="File"
                                                ref={register({
                                                    required: {
                                                        value: true,
                                                        message:
                                                            'Please select a file for upload',
                                                    },
                                                })}
                                            />
                                            <span className="file-cta">
                                                <span className="file-icon">
                                                    <FaSave />
                                                </span>
                                                <span className="file-label">
                                                    Upload a File
                                                </span>
                                            </span>
                                            {fileName ? (
                                                <span className="file-name has-text-link">
                                                    {fileName}
                                                </span>
                                            ) : null}
                                        </label>
                                    </div>
                                    {errors && errors.File ? (
                                        <div className="eddErrorWrapper">
                                            <p className="help is-danger has-text-left">
                                                {errors.File.message}
                                            </p>
                                        </div>
                                    ) : null}
                                </form>
                                <EDDValidation isValid={valid} />
                                <div className="column is-12">
                                    <div className="columns is-centered">
                                        <div className="column is-6">
                                            <DownloadTemplate templateType="SampleResult" />
                                        </div>
                                    </div>
                                </div>
                                <div className="eddButtonWrapper">
                                    <button
                                        type="submit"
                                        form="uploadForm"
                                        className="button is-link"
                                    >
                                        {`${valid ? 'Submit' : 'Validate'}`}
                                    </button>
                                    <button
                                        type="cancel"
                                        onClick={() =>
                                            history.push(
                                                urls.manageSampleResults
                                            )
                                        }
                                        className="button green"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EDDDialogue
