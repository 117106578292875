import React, { useContext, useCallback } from 'react'
import { FaPlus } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import urls from '../../../utils/constants/urls'
import { DataContext } from './DataContext'

export default () => {
    const { initializeState, setFacilityID, setActiveAgency, setActivePanel, setFormDirty, setErrorState } = useContext(
        DataContext
    )

    const clearAllDataContext = useCallback(() => {
        setActivePanel(null)
        initializeState({})
        setFacilityID(null)
        setActiveAgency(null)
        setFormDirty(false)
        setErrorState({})
    }, [])

    return (
        <Link to={urls.uploadDialogue} onClick={() => clearAllDataContext()} className="border-none newUploadButton">
            <div className="button is-primary is-medium">
                <span>Start a New Submission</span>
                <span className="icon">{<FaPlus />}</span>
            </div>
        </Link>
    )
}
