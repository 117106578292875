import React, { useContext, useState, useEffect, useCallback } from 'react'
import dayjs from 'dayjs'
import {
    ComposedChart,
    Line,
    YAxis,
    XAxis,
    Tooltip,
    Scatter,
    Label,
    CartesianGrid,
    // ReferenceLine,
    ResponsiveContainer,
    ReferenceArea,
    ReferenceLine,
} from 'recharts'

import { DataContext } from './DataContext'
import Message from '../../../elem/chart/Message'
import ChartTooltip from '../../../elem/chart/Tooltip'
import filterTimeData from '../../../../utils/chart/timeWindow'
import { dateToString } from '../../../../utils/dateUtils'
import { getAxisYDomain } from '../../../../utils/chart/slice'

const NitrateChart = () => {
    const { 
        detailData, 
        clearinghouseNumber,
        timeWindow,
        isLoading,
        zoomTrigger, 
    } = useContext(DataContext)
    const [data, setData] = useState([])

    useEffect(() => {
        if (detailData && detailData.nitrateChart && detailData.nitrateChart.length) {
            const d = 
                filterTimeData(detailData.nitrateChart, timeWindow, 'SampleDate')
                .map(d => ({...d, SampleDate: dayjs(d.SampleDate).toDate().getTime()}))// convert sample date to unix time for x-axis range
            setData(d)
        } else {
            setData([])
        }
    }, [detailData, timeWindow])

    const [left, setLeft] = useState('dataMin')
    const [right, setRight] = useState('dataMax')
    const [refAreaLeft, setRefAreaLeft] = useState('')
    const [refAreaRight, setRefAreaRight] = useState('')
    const [top, setTop] = useState('dataMax')
    const [bottom, setBottom] = useState('dataMin')

    useEffect(() => {
        zoomOut()
    }, [zoomTrigger])

    const zoom = useCallback(() => {
        let RAL = refAreaLeft
        let RAR = refAreaRight
        if (RAL === RAR || RAR === '') {
            RAL = ''
            RAL = ''
            return
        }

        // xAxis domain
        if (RAL > RAR) {
            const temp = RAL
            RAL = RAR
            RAR = temp
        }

        // yAxis domain
        const leftAxisDomain = getAxisYDomain(
            RAL,
            RAR,
            x => x.includes('NitrateLevel'),
            0,
            data,
            'SampleDate'
        )
        setBottom(leftAxisDomain[0])
        setTop(leftAxisDomain[1])
        setLeft(RAL)
        setRight(RAR)
        setRefAreaRight('')
        setRefAreaLeft('')
    }, [refAreaLeft, refAreaRight, data])

    const zoomOut = useCallback(() => {
        setRefAreaLeft('')
        setRefAreaRight('')
        setLeft('dataMin')
        setRight('dataMax')
        setTop('dataMax')
        setBottom('dataMin')
    }, [data])

    // if loading || no data, return nothing
    if (isLoading) {
        return null
    }
    if (!(data.length)) {
        return <Message text={`No Nitrate Data Available`} />
    }

    // construct chart title
    const chartTitle = `Clearinghouse # ${clearinghouseNumber} - Nitrate Levels`

    // construct chart
    const yAxisLabel = 'Concentration (mg/l)'
    const xAxisLabel = 'Sample Date'

    // shared chart props
    const animationDuration = 200

    return (
        <div className="chart">
            <div className="chartTitle">{chartTitle}</div>
            <div className="chartWrapper">
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                        data={data}
                        margin={{
                            top: 10,
                            right: 50,
                            left: 0,
                            bottom: 15,
                        }}
                        onMouseDown={e => e && e.activeLabel && setRefAreaLeft(e.activeLabel)}
                        onMouseMove={e => e && e.activeLabel && refAreaLeft && setRefAreaRight(e.activeLabel)}
                        onMouseUp={() => zoom()}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                            allowDataOverflow
                            type="number"
                            dataKey="SampleDate" 
                            domain={[left, right]}
                            tickFormatter={(unixTime) => dateToString(unixTime)}
                        >
                            <Label
                                value={xAxisLabel}
                                offset={-10}
                                position="insideBottom"
                                className="nitrateChartXAxisLabel"
                            />
                        </XAxis>
                        <YAxis
                            allowDataOverflow
                            type="number"
                            domain={[bottom, top]}
                        >
                            <Label
                                value={yAxisLabel}
                                angle={-90}
                                offset={20}
                                position="insideLeft"
                                className="nitrateChartYAxisLabel"
                            />
                        </YAxis>
                        <Tooltip 
                            content={<ChartTooltip />} 
                        />
                        <Line
                            type="monotone"
                            dataKey="NitrateLevel"
                            name="Nitrate Level"
                            unit={'mg/l'}
                            stroke={"#8884d8"}
                            strokeWidth={1}
                            activeDot={{ r: 5 }}
                            dot={{r: 3, stroke: '#8884d8', fill: '#8884d8'}}
                            animationDuration={animationDuration}
                        />
                        <Scatter
                            dataKey="NitrateLevel"
                            lineType="fitting"
                            name={'NoTooltipItem'}
                            line={{
                                stroke: "#82ca9d",
                                strokeWidth: 1,
                            }}
                            fill="none"
                            animationDuration={animationDuration}
                        />
                        {refAreaLeft && refAreaRight ? (
                            <ReferenceArea
                                x1={refAreaLeft}
                                x2={refAreaRight}
                                strokeOpacity={0.3}
                            />
                        ) : null}
                        <ReferenceLine
                            y={10}
                            stroke="red"
                            strokeDasharray="3 3"
                            animationDuration={animationDuration}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default NitrateChart
