import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import {
    ComposedChart,
    Line,
    YAxis,
    XAxis,
    Tooltip,
    Scatter,
    Label,
    CartesianGrid,
    // ReferenceLine,
    ResponsiveContainer,
    Legend,
    ReferenceArea,
    ReferenceLine,
} from 'recharts'

import { DataContext } from './DataContext'
import Message from '../../../elem/chart/Message'
import Spinner from '../../../elem/Spinner'
import ChartTooltip from '../../../elem/chart/Tooltip'
import filterTimeData from '../../../../utils/chart/timeWindow'
import { dateToString } from '../../../../utils/dateUtils'
import { AppStateContext } from '../AppStateContext'
import { getAxisYDomain } from '../../../../utils/chart/slice'
import moment from 'moment/moment'

const aggregateSeries = [
    {
        name: 'Minimum',
        color: '#984ea3',
        dataKey: 'Minimum',
    },
    {
        name: 'Maximum',
        color: '#ff7f00',
        dataKey: 'Maximum',
    },
    {
        name: 'Median',
        color: '#377eb8',
        dataKey: 'Median',
    }
]

export default () => {
    const { 
        chartData,
        loading, 
        timeWindow, 
        tooManyFeatures,
        isCollapsed,
        zoomTrigger,
        getSummaryMedianData,
        summaryMedianData,
        summaryLoading,
    } = useContext(DataContext)
    const { mapState: { selectedFeatures } } = useContext(AppStateContext)

    const [data, setData] = useState([])

    const [left, setLeft] = useState('dataMin')
    const [right, setRight] = useState('dataMax')
    const [refAreaLeft, setRefAreaLeft] = useState('')
    const [refAreaRight, setRefAreaRight] = useState('')
    const [top, setTop] = useState('dataMax')
    const [bottom, setBottom] = useState('dataMin')

    const zoom = useCallback(() => {
        let RAL = refAreaLeft
        let RAR = refAreaRight
        if (RAL === RAR || RAR === '') {
            RAL = ''
            return
        }

        // xAxis domain
        if (RAL > RAR) {
            const temp = RAL
            RAL = RAR
            RAR = temp
        }

        const filterFunction = (x) => 
            !x.includes('NumberOfSamples')
            && !x.includes('DateString')
        // yAxis domain
        const leftAxisDomain = getAxisYDomain(RAL, RAR, filterFunction, 0, data, 'DateString')
        setBottom(leftAxisDomain[0])
        setTop(leftAxisDomain[1])

        setLeft(RAL)
        setRight(RAR)
        setRefAreaRight('')
        setRefAreaLeft('')
    }, [refAreaLeft, refAreaRight, data])

    const zoomOut = useCallback(() => {
        setRefAreaLeft('')
        setRefAreaRight('')
        setRight('dataMax')
        setLeft('dataMin')
        setTop('dataMax')
        setBottom('dataMin')
    }, [data])

    useEffect(() => {
        zoomOut()
    }, [zoomTrigger])

    useEffect(() => {
        if (right && right !== "dataMax" && left && left !== "dataMin") {
            let startDate = moment(left).format('MM/DD/YYYY').toString()
            let endDate = moment(right).format('MM/DD/YYYY').toString()
            getSummaryMedianData(selectedFeatures, startDate, endDate)
        } else if (selectedFeatures && selectedFeatures.length) {
            getSummaryMedianData(selectedFeatures)
        }
    }, [left, right, selectedFeatures])

    const summaryData = useMemo(() => {
        const dateFilteredData = right && right !== "dataMax" && left && left !== "dataMin"
            ? data.filter(x => x['DateString'] < right + 1 && x['DateString'] > left - 1)
            : data
        var maximumArray = dateFilteredData.map(x => x.Maximum)
        var minimumArray = dateFilteredData.map(x => x.Minimum)
        return ([
            {
                code: 'max',
                label: 'Max',
                value: Math.max(...maximumArray),
            },
            {
                code: 'min',
                label: 'Min',
                value: Math.min(...minimumArray),
            },
            {
                code: 'med',
                label: 'Med',
                value: summaryMedianData,
            },
        ])
    }, [data, summaryMedianData])
    
    useEffect(() => {
        if (chartData && chartData.length) {
            setData(
                filterTimeData(chartData, timeWindow, 'DateString').map(
                    d => ({
                        ...d,
                        DateString: dayjs(d.DateString)
                            .toDate()
                            .getTime(),
                    })
                )
            )
        } else {
            setData([])
        }
    }, [chartData, timeWindow])

    if (loading && !isCollapsed) {
        return <Spinner extraClass="chartWrapper" />
    }

    if (tooManyFeatures) {
        return (
            <Message
                isCollapsed={isCollapsed}
                text={`Too Many Wells Selected`}
                subText={`Select less than 2100 wells to generate aggregate statistics`}
            />
        )
    }

    if (!(selectedFeatures.length)) {
        return <Message 
            isCollapsed={isCollapsed}
            text={'No Wells Selected'}
            subText={`Select up to 2100 wells to generate aggregate statistics`}
        />
    }

    if (selectedFeatures.length && !(chartData && chartData.length)) {
        return <Message
            isCollapsed={isCollapsed}
            text={'No Nitrate Data Available'} 
            subText={`Select wells with Nitrate samples to generate aggregate statistics`}
        />
    }

    // construct chart
    const yAxisLabel = 'Concentration (mg/l)'
    const xAxisLabel = 'Sample Date'

    // shared chart props
    const animationDuration = 200

    // create lines for min, max, and median nitrate levels
    const lines = aggregateSeries.map(({ name, dataKey, color }, idx) => (
        <Line
            key={`time-series-${name}`}
            type="monotone"
            dataKey={dataKey}
            name={name}
            unit={'mg/l'}
            stroke={color}
            strokeWidth={0}
            activeDot={{ r: 5, fill: color, stroke: color }}
            dot={{ r: 1, stroke: color, fill: color }}
            animationDuration={animationDuration}
        />
    ))

    // create data points for number of samples
    const numberOfSamples = (<Line 
        key={`time-series-numberOfSamples`}
        type="monotone"
        dataKey={'NumberOfSamples'}
        name={'Number of Samples'}
        stroke={'blue'}
        unit={''}
        strokeWidth={0}
        opacity={0}
        legendType={'none'}
        dot={false}
        activeDot={false}
     />)

    const dots = aggregateSeries.map(({ name, dataKey, color }, idx) => (
        <Scatter
            key={`best-fit-${name}`}
            dataKey={dataKey}
            lineType="fitting"
            name={'NoTooltipItem'}
            line={{
                stroke: color,
                strokeWidth: 1,
            }}
            fill="none"
            animationDuration={animationDuration}
        />
    ))
    const RenderLegend = props => {
        const { payload, summaryData } = props
        return (
            <div className='waterLevelAggregateLegend'>
                <ul className="recharts-default-legend legendWrapper">
                    {payload.map((entry, index) => (entry.value !== 'NoTooltipItem' && entry.value !== 'Number of Samples') ? (
                        <li className="recharts-legend-item legendItem" key={`item-${index}`}>
                            <svg className="recharts-surface" width="14" height="14" viewBox="0 0 32 32" version="1.1" style={{display: 'inline-block', verticalAlign: 'middle', marginRight: '4px'}}>
                                <path stroke-width="4" fill="none" stroke={entry.color} d="M0,16h10.666666666666666
                                    A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
                                    H32M21.333333333333332,16
                                    A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16" className="recharts-legend-icon" />
                            </svg>
                            <span className="recharts-legend-item-text">{entry.value}</span>
                        </li>
                    ) : null)}
                </ul>
                <div className='columns legendItemContainer'>
                    {summaryData.map(x => (
                        <div className='column recharts-legend-item legendItem flex-basis-auto'>
                            <span className="recharts-legend-item-text"><b>{x.label}:</b> {x.value}</span>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    return (
        <div className="chart">
            {/* <div className="chartTitle">{chartTitle}</div> */}
            <div className={ isCollapsed ? `is-collapsed` : `chartWrapper` }>
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                        data={data}
                        margin={{
                            top: 10,
                            right: 50,
                            left: 0,
                            bottom: 25,
                        }}
                        onMouseDown={e => e && e.activeLabel && setRefAreaLeft(e.activeLabel)}
                        onMouseMove={e => e && e.activeLabel && refAreaLeft && setRefAreaRight(e.activeLabel)}
                        // eslint-disable-next-line react/jsx-no-bind
                        onMouseUp={() => zoom()}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            allowDataOverflow
                            type="number"
                            dataKey="DateString"
                            domain={[left, right]}
                            tickFormatter={unixTime => dateToString(unixTime)}
                        >
                            <Label
                                value={xAxisLabel}
                                offset={-10}
                                position="insideBottom"
                                className="nitrateChartXAxisLabel"
                            />
                        </XAxis>
                        <YAxis 
                            allowDataOverflow
                            type="number"
                            domain={[bottom, top]} 
                            interval='preserveStartEnd'
                            yAxisId={0}
                        >
                            <Label
                                value={yAxisLabel}
                                angle={-90}
                                offset={20}
                                position="insideLeft"
                                className="nitrateChartYAxisLabel"
                            />
                        </YAxis>
                        <Tooltip content={<ChartTooltip />} />
                        <Legend 
                            verticalAlign="bottom"
                            height={36}
                            content={summaryLoading ? <Spinner extraClass="chartWrapper" /> : <RenderLegend summaryData={summaryData} />}
                        />
                        {lines}
                        {dots}
                        {numberOfSamples}
                        <ReferenceLine
                            y={10}
                            stroke="red"
                            strokeDasharray="3 3"
                            animationDuration={animationDuration}
                        />
                        {refAreaLeft && refAreaRight ? (
                            <ReferenceArea
                                yAxisId={0}
                                x1={refAreaLeft}
                                x2={refAreaRight}
                                strokeOpacity={0.3}
                            />
                        ) : null}
                        {/* <ReferenceLine
                            y={10}
                            stroke="red"
                            strokeDasharray="3 3"
                            animationDuration={animationDuration}
                        /> */}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}
