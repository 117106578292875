import React, { useContext, useState } from 'react'
import dayjs from 'dayjs'

import { ParameterContext } from '../../../wrappers/ParameterContext'
import DateSelectComparisonOperator from './DateSelectComparisonOperator'
import DateSelectComponent from './DateSelectComponent'

const DateSelect = ({
    name,
    fieldName,
    comparisonOperators,
    helper,
    register,
    registerParams,
    unregister,
    setValue,
    error,
    formWidth,
    formName,
    watch
}) => {
    const { params } = useContext(ParameterContext)
    const filterName = `${name}Value`
    const comparisonOperatorName = `${name}Operator`
    const initialFilterValues = params[formName] && params[formName][filterName]
        ? decodeURIComponent(params[formName][filterName]).split(',').map(x => new Date(x))
        : null
    const initialDates = initialFilterValues
        ? initialFilterValues.map(x => dayjs(x)
              .format('MM/DD/YYYY')
              .toString())
        : ['']

    const initialComparisonOperatorValues =
        comparisonOperators && params[formName] && decodeURIComponent(params[formName][comparisonOperatorName])
            ? decodeURIComponent(params[formName][comparisonOperatorName]).split(',')
            : [null]

    const [between, setBetween] = useState(
        initialComparisonOperatorValues.length > 1
    )
    
    // toggle between state
    const checkForBetweenOperator = e => {
        if (e.currentTarget.value === 'BETWEEN') {
            setBetween(true)
        } else {
            setBetween(false)
        }
    }

    return (
        <div className={`column ${formWidth === 'full' ? 'is-one-third' : 'is-full'} no-vertical-padding formInputWrapper`}>
            <div className="formInputLayout">
                <div className="field">
                    <div className="field-label is-small">
                        <label className="label">{fieldName}</label>
                    </div>
                    <div className="field-body">
                        <div className={`field has-addons`}>
                            <DateSelectComparisonOperator 
                                {
                                    ...{
                                        comparisonOperators,
                                        comparisonOperatorName,
                                        register,
                                        unregister,
                                        between,
                                        initialComparisonOperatorValues,
                                        checkForBetweenOperator
                                    }
                                }
                            />
                            <DateSelectComponent
                                {...{
                                    filterName,
                                    error,
                                    initialDates,
                                    register,
                                    registerParams,
                                    helper,
                                    unregister,
                                    between,
                                    setValue,
                                    watch
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DateSelect
