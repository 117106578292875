import React, {
    useState,
    useContext,
    useEffect,
    useCallback,
    useMemo,
} from 'react'
import { useForm } from 'react-hook-form'

import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import { DataContext } from '../DataContext'
import withConfig from '../../../wrappers/withConfig'
import { aliasHelperText } from '../../../../utils/submissions/helperText'

const FacilityAliasForm = () => {
    const { activePanel } = useContext(DataContext)

    return (
        activePanel === 'Well Alias Information' && <FacilityAliasFormComponent />
    )
}

const FacilityAliasFormComponent = withConfig(({ config }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const {
        activePanel,
        activeAgency,
        submissionState,
        viewOnly,
        setFormMethods,
        setFormDirty,
        setSubmissionState,
    } = useContext(DataContext)
    const [alias, setAlias] = useState()
    const [aliasData, setAliasData] = useState(submissionState.facilityAlias)
    const [loading, setLoading] = useState(false)
    const defaultValues = useMemo(() => aliasData ? {'Alias': aliasData.Alias} : null, [aliasData])
    const formConfig = defaultValues ? {mode: 'onChange', defaultValues} : {mode: 'onChange'}
    const formMethods = useForm(formConfig)
    const { handleSubmit, formState } = formMethods
    const { dirty } = formState
    const { API_URL } = config
    const [duplicateAliasError, setDuplicateAliasError] = useState(null)

    useEffect(() => {
        setFormMethods(formMethods)
    }, [defaultValues])

    useEffect(() => {
        formMethods.reset(defaultValues)
    }, [defaultValues])

    const updateFacilityAlias = useCallback((currentsubmissionState) => {
        setLoading(true)
        let uploadID = currentsubmissionState && currentsubmissionState.upload && currentsubmissionState.upload.UploadID
        authenticatedFetch(
            `${API_URL}/upload/facilitySearch?alias=${alias}&DNRWellID=${null}&agencyCode=${activeAgency}&uploadID=${uploadID}`
        )
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(res => {
                setAliasData(prevAliasData => ({
                    ...prevAliasData,
                    Alias: alias,
                    IsLegacy: 0,
                    aliasChanged: 1
                }))
                if (res.error) {
                    setDuplicateAliasError(res.error)    
                    return 
                } else {
                    setDuplicateAliasError(null)
                }
            })
            .catch(e => {
                setDuplicateAliasError(
                    'A well with that Alias already exists for your agency.'
                )
            })
            .finally(() => setLoading(false))
    }, [alias])

    useEffect(() => {
        setSubmissionState(prevSubmissionSate => ({
            ...prevSubmissionSate,
            facilityAlias: aliasData,
        }))
        setAlias(aliasData.Alias)
    }, [aliasData])

    useEffect(() => {
        setAliasData(
            submissionState.facilityAlias ? submissionState.facilityAlias : null
        )
    }, [submissionState])

    useEffect(() => {
        setFormDirty(dirty)
    }, [dirty])

    return (
        <div
            className={`columns is-multiline is-centered ${
                activePanel !== 'Well Alias Information' ? 'is-hidden' : ''
            }`}
        >
            <div className="column is-12">
                <form
                    className="form"
                    onSubmit={handleSubmit(() => updateFacilityAlias(submissionState))}
                >
                    <div className="is-size-4 has-text-centered matchingFacilityText">
                        <span className="has-text-weight-semibold">
                            Well Alias Information:
                        </span>
                    </div>
                    <div className="columns is-vcentered is-centered">
                        <div className="column is-12">
                            <div className="field is-horizontal ">
                                <div className="field-label is-align-items-center">
                                    <label className="label">Alias:</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <input
                                                disabled={`${
                                                    viewOnly ? 'true' : ''
                                                }`}
                                                className="input is-fullwidth "
                                                name={'Alias'}
                                                ref={formMethods.register}
                                                // value={alias}
                                                onChange={e => {
                                                    setAlias(e.target.value)
                                                }}
                                            />
                                        </div>
                                        <span className="help has-text-grey">
                                            {aliasHelperText}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!viewOnly && (
                        <div className="columns is-centered is-multiline">
                            <div className="column is-12 has-text-centered">
                                {duplicateAliasError ? (
                                    <div className="has-text-danger">
                                        {duplicateAliasError}
                                    </div>
                                ) : null}
                            </div>
                            <div className="column is-4 buttonWrapper">
                                <div className="saveButtonWrapper">
                                    <button
                                        className={`button is-link is-medium ${
                                            loading ? 'is-loading' : ''
                                        }`}
                                        type="submit"
                                    >
                                        Save
                                    </button>
                                </div>
                                <div className="saveButtonWrapper">
                                    <button
                                        type="button"
                                        className="button is-medium"
                                        onClick={() => {
                                            return formMethods.reset()
                                        }}
                                    >
                                        {`Reset`}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </form>
            </div>
        </div>
    )
})

export default FacilityAliasForm
